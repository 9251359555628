/** This module contains the component for selecting an entity from the list of entities in the workspace 
 *  view.  The selected entity becomes the group by in the query.
 *  @module
 */

import React, { useState, useEffect, useMemo } from "react";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums";
import { InputGroup, Button, HTMLSelect } from "@blueprintjs/core";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { toLower } from 'lodash';
import { STRINGS } from "app-strings";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import { getArDataSources } from 'utils/stores/GlobalDataSourceTypeStore';
import "./EntityList.scss";

/** this interface defines the properties passed into the EntityList React component. */
export interface EntityListProps {
    /** a String with the object type whose entities are to be displayed. */
    objType: string;
}

/** this interface defines the information that is in the drag and drop data object. */
export interface DragData {
    /** a String with the object type in the DO metadata. */ 
    objType: string;
    /** a string with the data source id. */
    dataSource: string;
    /** a string with the id of the group by. */
    groupBy: string;
}

const EntityList = (props: EntityListProps): JSX.Element => {
    const [pinnedCategoryOpen, setPinnedCategoryOpen] = useState<boolean>(true);
    const [dsCategoryOpen, setDsCategoryOpen] = useState<boolean>(true);
    const [vpCategoryOpen, setVpCategoryOpen] = useState<boolean>(true);
    const [listsCategoryOpen, setListsCategoryOpen] = useState<boolean>(true);
    const [pinnedFilterValue, setPinnedFilterValue] = useState<string>('');
    const [listsFilterValue, setListsFilterValue] = useState<string>('');
    const [entities, setEntities] = useState<GenericKey[] | undefined>([]);
    const [pinned, setPinned] = useState<Array<string> | undefined>([]);

    const [objType, setObjType] = useState<string>(props.objType);
    const [vp, setVp] = useState<string>("all");

    useEffect(() => {
        let defGroupBys: GenericKey[] = [];
        if (DataOceanUtils.dataOceanMetaData.obj_types?.[objType]?.group_by_required) {
            const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
                DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[objType].keys || []
            );
            defGroupBys = expandedKeys;
        } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[objType]?.group_by?.length) {
            defGroupBys = (DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by || []).map((key) => {
                return {id: key, ...DataOceanUtils.dataOceanMetaData.keys[key]} as GenericKey;
            });
        } else {
            // This is fake and is made up because there is no group by in this case.
            defGroupBys = [{id: objType, type: "string", label: DataOceanUtils.dataOceanMetaData.obj_types?.[objType].label}];
        }
        
        if (listsFilterValue) {
            setEntities(defGroupBys?.filter(groupByDef => toLower(groupByDef.label).includes(toLower(listsFilterValue))));
        } else {
            setEntities(defGroupBys);
        }

        const pinnedEntityNames = [];
        if (pinnedFilterValue) {
            setPinned(pinnedEntityNames.filter(pinnedName => toLower(pinnedName).includes(toLower(pinnedFilterValue))));
        } else {
            setPinned(pinnedEntityNames);
        }

    }, [listsFilterValue, pinnedFilterValue, objType]);

    const handleListsFilterChange = (event) => {
        setListsFilterValue(event.target.value);
    };

    const handleListsFilterClear = () => {
        const inputField = document.getElementById('listsFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setListsFilterValue('');
        }
    }

    const listsFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handleListsFilterClear}
        />
    );

    const handlePinnedFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPinnedFilterValue(event.currentTarget.value);
    };

    const handlePinnedFilterClear = () => {
        const inputField = document.getElementById('pinnedFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setPinnedFilterValue('');
        }
    }

    const pinnedFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handlePinnedFilterClear}
        />
    );

    const dragPinned = (event: any, data: string) => {
        event.dataTransfer.setData("pinnedInfo", JSON.stringify(data));
    }

    const drag = (event: any, data: DragData) => {
        event.dataTransfer.setData("queryInfo", JSON.stringify(data));
    }

    const objTypes: {value: string, label: string}[] = useMemo(
        () => {
            const objTypesTemp: {value: string, label: string}[] = [];
            for (const objType in DataOceanUtils.dataOceanMetaData.obj_types) {
                objTypesTemp.push({value: objType, label: DataOceanUtils.dataOceanMetaData.obj_types[objType].label});
            }
            return objTypesTemp;    
        }, []
    ); 

    const arDatasources: {value: string, label: string}[] = useMemo(
        () => {
            if (DataOceanUtils.dataOceanMetaData.obj_types[objType].filters.includes("data_source")) {
                return (getArDataSources() || []).map(ds => {
                    return { label: ds.name, value: ds.id }
                });
            } else {
                return [{label: "None", value: ""}];
            }
        }, [objType]
    )

    return (<div className="entity-list p-2 pt-3 bg-light">
        <div className="entity-list-header display-7 pb-3">{STRINGS.navigator.entityListWidget.title}</div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name" onClick={() => setPinnedCategoryOpen(!pinnedCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={pinnedCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold" >
                    {STRINGS.navigator.entityListWidget.pinnedListTitle}
                </span>
                <button className="pin-entity"><Icon icon={APP_ICONS.ADD} /></button>
            </div>
            {pinnedCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <InputGroup
                        id={"pinnedFilterInput"}
                        leftIcon="search"
                        rightElement={pinnedFilterClearIcon}
                        onChange={handlePinnedFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={pinnedFilterValue}
                        className="mb-3 mt-2"
                    />
                    {pinned?.length ? pinned.map((pinnedName, index) => (
                        <div className="entity-list-item" draggable onDragStart={(event) => { dragPinned(event, pinnedName); }} key={index}>
                            <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                            <span>{pinnedName}</span>
                        </div>)) : (
                            <div className="entity-list-item display-9">{STRINGS.navigator.entityListWidget.noPinnedItemsFound}</div>
                        )}
                </div>
            }
        </div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name" onClick={() => setDsCategoryOpen(!dsCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={dsCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold" >{STRINGS.navigator.entityListWidget.dsListTitle}</span>
            </div>
            {dsCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <HTMLSelect
                        name="obj-types"
                        disabled={false}
                        options={objTypes}
                        value={objType}
                        onChange={(event) => {
                            setObjType(event.currentTarget.value);
                        }}
                    />
                </div>
            }
        </div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name" onClick={() => setVpCategoryOpen(!vpCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={vpCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold" >{STRINGS.navigator.entityListWidget.vpListTitle}</span>
            </div>
            {vpCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <HTMLSelect
                        name="vantage-points"
                        disabled={false}
                        options={arDatasources}
                        value={vp}
                        onChange={(event) => {
                            setVp(event.currentTarget.value);
                        }}
                    />
                </div>
            }
        </div>
        <div className="entity-list-category">
            <div onClick={() => setListsCategoryOpen(!listsCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={listsCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="pl-1 font-weight-bold">{STRINGS.navigator.entityListWidget.listsListTitle}</span>
            </div>
            {listsCategoryOpen && 
                <div className="entity-list-items font-weight-normal pt-2">
                    <InputGroup
                        id={"listsFilterInput"}
                        leftIcon="search"
                        rightElement={listsFilterClearIcon}
                        onChange={handleListsFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={listsFilterValue}
                        className="mb-3 mt-2"
                    />
                    {entities?.length ? entities.map((groupByDef, index) => (
                        <div className="entity-list-item" draggable key={index}
                            onDragStart={(event) => {
                                const data: DragData = {
                                    objType,
                                    dataSource: vp,
                                    groupBy: groupByDef.id
                                };
                                drag(event, data); 
                            }}
                        >
                            <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                            <span>{groupByDef.label}</span>
                        </div>)) : (
                            <div className="entity-list-item display-9">{STRINGS.navigator.entityListWidget.noListsFound}</div>
                        )}
                </div>
            }
        </div>
    </div>);
}

export { EntityList };

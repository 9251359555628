/** This module contains utilities for editing subflow nodes and validating subflow nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { AdditionalInfoOptions } from "utils/runbooks/RunbookValidationUtils";
import { RunbookNode } from "utils/services/RunbookApiService";
import { Version } from "utils/Version.class";
import { PrimitiveVariableType } from "utils/runbooks/VariablesUtils";

/** Utility class for subflow node,*/
export class SubflowNodeUtils /*extends NodeUtils*/ {
    /** the error messages for the subflow node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.subflowNode;

    /** Check if subflow node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes. 
     *  @param subflows the array of runbookNode objects with the list of subflows.
     *  @param variables the map of variables by scope.
     *  @returns  is node valid. */
    static isNodeValid(
        nodeId: string | undefined | null, graphDef: GraphDef, subflows: Array<RunbookNode> = [], 
        variables?: VariableContextByScope
    ): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const warnings = [], errors = [];
        SubflowNodeUtils.validateNode(nodeId, warnings, errors, graphDef, subflows, variables)
        return errors.length === 0;
    }

    /** Check if a subflow node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param warnings - IN-OUT argument the array his populated with warning messages. Empty array if
     *       there are no warnings.
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes. 
     *  @param subflows the array of runbookNode objects with the list of subflows.
     *  @param variables the map of variables by scope. */
    static validateNode(
        nodeId: string, warnings: string[], errors: string[], graphDef: GraphDef, subflows: Array<RunbookNode> = [], 
        variables?: VariableContextByScope
    ): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        //super.validateNode(nodeId, errors, graphDef, variables);
        this.validateNodePropertiesFromGraphDef(curNode, graphDef, getProperties(curNode), warnings, errors, curNode?.name, subflows, variables);
    }

    /** Validates the property values for a given set complex variable node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param warnings the array of strings with any warnings that have been encountered.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param subflows the array of runbookNode objects with the list of subflows.
     *  @param variables the map of variables by scope. 
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, graphDef: GraphDef, currentProperties: Record<string, any>, warnings: string[], errors: Array<string>, label: string,
        subflows: Array<RunbookNode> = [], variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties?.configurationId) {
            errors.push(SubflowNodeUtils.errMsgs.configurationIdMissing);
        }

        const subflowId: string = currentProperties?.configurationId;
        if (subflowId) {
            let found = false;
            let showVersionWarning: boolean = false;
            let currentVersion: Version | undefined;
            let latestVersion: Version | undefined;
            for (const subflow of subflows) {
                if (subflow.id === subflowId) {
                    currentVersion = Version.parse(subflow.version || "");
                    latestVersion = currentVersion;
                    for (const versionInfo of subflow.otherVersions || []) {
                        let version: Version = Version.parse(versionInfo.version || "");
                        if (version.isGreaterThan(latestVersion)) {
                            showVersionWarning = true;
                            latestVersion = version;
                        }
                    };            
                    found = true;
                } else {
                    currentVersion = undefined;
                    for (const versionInfo of subflow.otherVersions || []) {
                        if (subflowId === versionInfo.id) {
                            currentVersion = Version.parse(versionInfo.version || "");
                            latestVersion = currentVersion;
                            found = true;
                            break;
                        }
                    };
                    if (currentVersion) {
                        let version: Version = Version.parse(subflow.version);
                        if (version.isGreaterThan(latestVersion!)) {
                            showVersionWarning = true;
                            latestVersion = version;
                            found = true;
                        }
                        for (const versionInfo of subflow.otherVersions || []) {
                            let version: Version = Version.parse(versionInfo.version || "");
                            if (version.isGreaterThan(latestVersion!)) {
                                showVersionWarning = true;
                                latestVersion = version;
                                found = true;
                            }
                        }
                    }            
                }
                if (found) {
                    break;
                }
            }    
            if (showVersionWarning) {
                graphDef.additionalInformation = AdditionalInfoOptions.OLD_SUBFLOW_WARNING;
                warnings.push(STRINGS.formatString(SubflowNodeUtils.errMsgs.oldSubflowVersion, {currentVersion: currentVersion!.toString(), latestVersion: latestVersion!.toString()}));
            }
        }    
        
        /*
        if (currentProperties?.variable?.name) {
            const structuredVariables: string[] = NodeUtils.getStructuredVariablesList(variables);
            if (!structuredVariables.includes(currentProperties.variable.name)) {
                if (currentProperties.variable.name.includes(RUNTIME_SCOPE)) {
                    errors.push(SubflowNodeUtils.errMsgs.incompatibleRuntimeVariable);
                } else if (currentProperties.variable.name.includes(INCIDENT_SCOPE)) {
                    errors.push(SubflowNodeUtils.errMsgs.incompatibleIncidentVariable);
                } else if (currentProperties.variable.name.includes(SUBFLOW_SCOPE)) {
                    errors.push(SubflowNodeUtils.errMsgs.incompatibleSubflowVariable);
                }
            }
        }
        */

        if (subflowId) {
            for (const subflow of subflows) {
                if (subflow.id === subflowId) {
                    const inputVariables = subflow?.inputVariables;
                    const inputContext = currentProperties?.in;
                    let hasEmptyRequiredFields = false;
                    if (inputContext?.length && inputVariables?.length) {
                        inputContext.forEach(item => {
                            const inputType = inputVariables?.find(inputVariable => inputVariable.name === item.inner)?.type;
                            if (inputType === PrimitiveVariableType.CONNECTOR && !item.outer) {
                                hasEmptyRequiredFields = true;
                            }
                            if (subflow.inputOrOutputValuesDescriptions?.find(description => description.valueName === item.inner)?.required && !item.outer) {
                                hasEmptyRequiredFields = true;
                            }
                        });
                    }
                    if (hasEmptyRequiredFields) {
                        errors.push(STRINGS.runbookEditor.errors.subflowNode.hasEmptyRequiredFields);
                    }
                }
            }
        }

        return errors;
    }
}

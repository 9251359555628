/** This file defines the generate AI instructions dialog React component. The generate AI instructions 
 *  dialog allows you to call a service to generate AI instructions for the user.
 *  @module */
import React, { useState, useEffect } from "react";
import { ErrorToaster, useStateSafePromise } from "@tir-ui/react-components";
import { Button, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { GenAiPromptRequest, GenAiPromptResponse, GenAiService } from "utils/services/GenAiApiService";
import { getErrorsFromApiResponse } from "utils/errors/ErrorUtils";

/** this interface defines the properties that are passed into the prompt generate dialog. */
interface GenerateInstructionsDialogProperties {
    /** a string with the user provided prompt, if any has been provided. */
    prompt: string;
    /** a boolean value, true if the dialog should be opened. */
    open: boolean;
    /** the handler for dialog close events. */
    onClose: (prompt: string) => void
}

/** Component for displaying a dialog to generate instructions for the ai node
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the generate instructions dialog. */
export function GenerateInstructionsDialog(props: GenerateInstructionsDialogProperties): JSX.Element {
    const [request, setRequest] = useState<GenAiPromptRequest>();
    const [loading, setLoading] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>(props.prompt);
    const [executeSafely] = useStateSafePromise();

    useEffect(() => {
        setResultText(props.prompt);
    }, [props.prompt]);

    useEffect(
        () => {
            if (request) {
                setLoading(true);
                const genAiPromise = executeSafely(GenAiService.generatePrompt(request));
                genAiPromise.then(
                    (result: GenAiPromptResponse) => {
                        if (result?.prompt?.length) {
                            setResultText(result.prompt);
                        }
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        ErrorToaster({ 
                            message: getErrorsFromApiResponse(
                                error, STRINGS.runbookEditor.nodeLibrary.nodes.genai.errors, 
                                STRINGS.runbookEditor.nodeLibrary.nodes.genai.genericInstrError
                            ) 
                        });
                    }
                );    
            }
        },
        [request, executeSafely]
    )

    return <Dialog title={STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsDialog.title} 
        isOpen={props.open} usePortal={true} className={"generate-prompt-dialog"}
        onClose={() => {
            props.onClose(resultText);
        }}
        //portalClassName={props.portalClassName}
        canOutsideClickClose={true}
        canEscapeKeyClose={false}
        isCloseButtonShown={true}
    >
        <div className={Classes.DIALOG_BODY}>
            <DataLoadFacade loading={loading} fullScreen={false} data={true} error={false} 
                showContentsWhenLoading={true} transparent={true} className="generate-prompt-conent-load-facade"
            >
                <TextArea
                    required={ true }
                    value={resultText}
                    name={ "generate-prompt-content" }
                    growVertically={ true }
                    fill={ true }
                    placeholder={STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsDialog.placeholder}
                    style={{minHeight: "120px", maxHeight: "600px"}}
                    onChange={ e => {
                        const prompt = e.target.value;
                        setResultText(prompt);
                    } }
                        onBlur={ e => {
                        //setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: e.target.value });
                    } }
                />
            </DataLoadFacade>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button active={true} outlined={true} aria-label="cancel" type="button" 
                    name="generate-dialog-close-button"
                    onClick={(evt) => {
                        props.onClose(resultText);
                    }}
                    text={STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsDialog.closeButton}
                />
                <Button active={true} outlined={true} disabled={!Boolean(resultText?.length && resultText.length > 0 && !loading)}
                    text={STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsDialog.generateButton}
                    type="button"
                    aria-label="test"
                    name="generate-dialog-generate-button"
                    intent={Intent.PRIMARY}
                    onClick={async (evt) => {
                        // Create a request object
                        const request: GenAiPromptRequest = createRequest(resultText);
                        setRequest(request);
                    }}
                />
            </div>
        </div>
    </Dialog>;
}

/** generates a request object from prompt suggestion.
 *  @param prompt a String with the prompt suggestion.
 *  @returns a GenAiPromptRequest object with the request. */
export function createRequest(prompt: string): GenAiPromptRequest {
    const request: GenAiPromptRequest = {
        prompt: prompt || ""
    };
    return request;
}

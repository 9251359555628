/** This module contains utilities for editing subflow input nodes and validating subflow input nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "../../types/GraphTypes";
import { GenericKey, NodeUtils } from "../../../../../utils/runbooks/NodeUtil";
import { DataOceanKey, DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { KeyDefinition } from "../transform/OutputDataElement";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { OUTPUT_ELEMENTS_LIMIT } from "../transform/OutputDataBlock";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";

export type KeysMetadata = {
    /** the keys that are supported by the Data Ocean. */
    keys: Record<string, DataOceanKey>;
}

/** an enum with all of the valid subflow node node properties. */
export enum SUBFLOW_NODE_EDIT_PROPS {
    TRIGGER_TYPE        = "triggerType",
    /** the output data format property. */
    OUTPUT_DATA_FORMAT  = "outputDataFormat",
    /** the synthetic metrics property. */
    SYNTH_METRICS       = "synthMetrics",
    /** the synthetic keys property. */
    SYNTH_KEYS          = "synthKeys",
    /** the incoming variables. */
    INCOMING_VARIABLES  = "inputVariables",
    /** the static values for the string incoming variables */
    STATIC_INPUT_VALUES_LISTS = "staticInputValuesLists",
    /** the descriptions for the input or output variables */
    INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS = "inputOrOutputValuesDescriptions",
    /** the outgoing variables. */
    OUTGOING_VARIABLES  = "outputVariables",
    /** the ids of the integrations that the connector is linked to */
    INTEGRATION_IDS     = "integrationIds",
    /** the ids of the tags that the connector is linked to  */
    TAG_IDS             = "tagIds",
    /** debug property */
    DEBUG               = 'debug',
}

/** this interface defines a subflow key, which extends a generic key and adds the 
 *  additional attributes needed by the subflow input node.  This basically matches the 
 *  transform node so we can think of unifying everywhere we define this. */
export interface SubflowKey extends GenericKey {
    /** the data ocean id if this key is tied to a data ocean key.  If it is not tied to the data ocean then
     *  this will be undefined. */
    dataOceanId?: string;
    /** if this key is tied to a data ocean key, this property contains the expanded list of data ocean 
     *  subkeys that are in the data ocean meta data. */
    dataOceanKeys?: Array<GenericKey>;
    /** a boolean value, true if this is a required key, false otherwise. */
    required?: boolean;
    /** a boolean value, true if this is a synthetic key, false otherwise. */
    synthetic?: boolean;
}

/** Utility class for subflow input node,*/
export class SubflowInputNodeUtils extends NodeUtils {
    /** the error messages for the transform node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.subflowInputNode;
    
    /** Check if transform node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes.
     *  @returns  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef)
        return errors.length === 0;
    }

    /** Check if a transform node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef);
        SubflowInputNodeUtils.validateNodePropertiesFromGraphDef(curNode, getProperties(curNode), errors, curNode?.name, variables);
    }

    /** Validates the property values for a given transform node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param variables the map of variables by scope.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, currentProperties: Record<string, any>, errors: Array<string>, label: string,
        variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties.outputDataFormat) {
            errors.push(SubflowInputNodeUtils.errMsgs.outputDataFormat);
        }

        /* Damien would like it to be pssible to have no inputs
        if (currentProperties.synthKeys?.length === 0) {
            errors.push(SubflowInputNodeUtils.errMsgs.oneKeyOrMetric);
        }
        */
        
        if (currentProperties.synthKeys?.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(SubflowInputNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.synthKeys?.length > 0) {
            const error = SubflowInputNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.synthKeys);
            if (error) {
                errors.push(error);
            }
        }

        // Check if there is overlap between the input and output variables
        const inputVariables = currentProperties?.inputVariables || [];
        const outputVariables = currentProperties?.outputVariables || [];
        if (inputVariables?.length && outputVariables?.length) {
            let foundOverlap: boolean = false;
            for (const variable of inputVariables) {
                if (outputVariables.includes(variable)) {
                    errors.push(SubflowInputNodeUtils.errMsgs.overlappingInputAndOutputVariables);
                    foundOverlap = true;
                    break;
                }
            }
            if (!foundOverlap) {
                for (const variable of outputVariables) {
                    if (inputVariables.includes(variable)) {
                        errors.push(SubflowInputNodeUtils.errMsgs.overlappingInputAndOutputVariables);
                        break;
                    }
                }    
            }
        }

        // Check to see if the variables exist
        if (inputVariables?.length || outputVariables?.length) {
            const variablesList: string[] = NodeUtils.getVariablesList(variables);
            if (inputVariables?.length) {
                for (const variable of inputVariables) {
                    if (!variablesList.includes(variable)) {
                        errors.push(SubflowInputNodeUtils.errMsgs.missingInputVariable);
                        break;
                    }
                }    
            }
            if (outputVariables?.length) {
                for (const variable of outputVariables) {
                    if (!variablesList.includes(variable)) {
                        errors.push(SubflowInputNodeUtils.errMsgs.missingOutputVariable);
                        break;
                    }
                }    
            }
        }

        return errors;
    }

    /** Validates the property values for a given transform node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties.outputDataFormat) {
            errors.push(SubflowInputNodeUtils.errMsgs.outputDataFormat);
        }

        /* Damien would like it to be pssible to have no inputs
        if (currentProperties.outputDataProperties?.length === 0) {
            errors.push(SubflowInputNodeUtils.errMsgs.oneKeyOrMetric);
        }
        */

        if (currentProperties.outputDataProperties?.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(SubflowInputNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.outputDataProperties?.length > 0) {
            const error = SubflowInputNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.outputDataProperties);
            if (error) {
                errors.push(error);
            }
        }

        const inputVariables = currentProperties?.inputVariables || [];
        const outputVariables = currentProperties?.outputVariables || [];
        if (inputVariables?.length && outputVariables?.length) {
            let foundOverlap: boolean = false;
            for (const variable of inputVariables) {
                if (outputVariables.includes(variable)) {
                    errors.push(SubflowInputNodeUtils.errMsgs.overlappingInputAndOutputVariables);
                    foundOverlap = true;
                    break;
                }
            }
            if (!foundOverlap) {
                for (const variable of outputVariables) {
                    if (inputVariables.includes(variable)) {
                        errors.push(SubflowInputNodeUtils.errMsgs.overlappingInputAndOutputVariables);
                        break;
                    }
                }    
            }
        }

        return errors;
    }

    /** Validates that the new added labels for keys or metrics do not match any existent data ocean label
     *  @param properties the output data keys configured for node
     *  @returns an error message if applicable. */
    static validateNewLabelsNotInDataOceanLabels(properties) {
        const allLabels = SubflowInputNodeUtils.getAllDataOceanLabels();
        for (const data of properties) {
            if (!data.dataOceanId) {
                if (allLabels.includes(data.label)) {
                    return SubflowInputNodeUtils.errMsgs.labelInDataOcean;
                }
            }
        }
    }

    /** Function to get a list with all the labels existend in data ocean metadata
     *  @returns a list of strings with all data ocean labels. */
    static getAllDataOceanLabels() {
        const objMetaData = DataOceanUtils.dataOceanMetaData;
        var dataOceanLabels: Array<string> = [];
        for (const element in objMetaData.keys) {
            dataOceanLabels.push(objMetaData.keys[element].label);
            const objKeys = objMetaData.keys as Object;
            let nestedElements = SubflowInputNodeUtils.getCompleteExpandedKeys(objKeys as KeysMetadata, element);
            for (const nestedElement of nestedElements) {
                dataOceanLabels.push(nestedElement.label);
            }
        }
        return dataOceanLabels;
    }
    
    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param inputType the object type that is currently being displayed in the logic node.
     *  @returns an array with all the key definitions. */
    static getCompleteExpandedKeys(objMetricMetaData: KeysMetadata, inputType: string): Array<KeyDefinition> {
        const expandedKeys: Array<KeyDefinition> = [];
        if (objMetricMetaData && inputType) {
                const keyDef = objMetricMetaData[inputType];
                if (keyDef?.type === "object") {
                    SubflowInputNodeUtils.expandCompleteKeyProperties(keyDef, inputType, expandedKeys);
                } else {
                    expandedKeys.push({
                        "label": objMetricMetaData[inputType]?.label,
                        "id": inputType,
                        "type": objMetricMetaData[inputType]?.type,
                        "unit": objMetricMetaData[inputType]?.unit ? objMetricMetaData[inputType].unit : "none",
                        "required": objMetricMetaData[inputType]?.primary_key ? objMetricMetaData[inputType].primary_key : false
                    });
                }
        }
        return expandedKeys;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param keyDef the Data Ocean key definition or one of the property definitions.
     *  @param parents a string with the parent information that should be appended to the key id.
     *  @param expandedKeys an array with all the keys in it for example {id: network_server.ipaddr, label: "Host", type: "string"}. */
    static expandCompleteKeyProperties(keyDef: DataOceanKey, parents: string, expandedKeys: Array<KeyDefinition>): void {
        if (!keyDef.properties) {
            expandedKeys.push({ id: parents, label: keyDef.label, type: keyDef.type, unit: keyDef.unit ? keyDef.unit : "none", required: keyDef.primary_key ? keyDef.primary_key : false});
        } else {
            for (const property in keyDef.properties) {
                SubflowInputNodeUtils.expandCompleteKeyProperties(keyDef.properties[property], parents + "." + property, expandedKeys);
            }
        }
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param synthKeys the array of transform node keys.
     *  @returns an array with all the key definitions. */
    static getExpandedKeysForSynthKeys(objMetricMetaData: DataOceanMetadata, synthKeys: Array<SubflowKey>): Array<SubflowKey> {
        let expandedTransformKeys: Array<SubflowKey> = [];
        synthKeys.forEach((key) => {
            if (key.dataOceanId) {
                let expandedKeys: Array<GenericKey> = key.dataOceanKeys || [];
/*
                let expandedKeys: Array<GenericKey> = [];
                const keyId = key.dataOceanId;
                const keyDef = objMetricMetaData.keys[keyId];
                NodeUtils.expandKeyProperties(keyDef, keyId, expandedKeys);
*/                
                expandedTransformKeys = expandedTransformKeys.concat(expandedKeys);
            } else {
                expandedTransformKeys.push({...key, synthetic: true});
            }
        });
        return expandedTransformKeys;
    }

    /** returns whether or not the specified object type has any of the specified keys.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param synthKeys the array of transform node keys.
     *  @param keys a string array with the keys to check for.  If any match then the function should return true.
     *  @returns returns true if any of the keys are used by this object type. */
    static hasAnyKey(objMetricMetaData: DataOceanMetadata, synthKeys: Array<SubflowKey>, keys: Array<string>): boolean {
        if (keys && objMetricMetaData && synthKeys?.length) {
            for (const key of synthKeys) {
                if (key.dataOceanId) {
                    const keyId = key.dataOceanId;
                    if (keys.includes(keyId)) {
                        return true;
                    } else if (DataOceanUtils.hasAnySubKey(DataOceanUtils.dataOceanMetaData.keys[keyId].properties, keys)) {
                        return true;
                    }
                }
            };    
        }
        return false;
    }

    /** Checks if all the subkeys in metadata match the subkeys saved in the runbook definition
     *  @param node node information from graph definition
     *  @returns a warning message if applicable. */
    static allSubkeysInMetadata(node: NodeDef) {
        const keysMetaData = DataOceanUtils.dataOceanMetaData.keys as Object;
        const runbookDefSubkeys = node?.properties?.find((item) => item.key === "synthKeys")?.value;
        for (const keyElem of runbookDefSubkeys) {
            if (keyElem.dataOceanId) {
                const expandedKeys = SubflowInputNodeUtils.getCompleteExpandedKeys(keysMetaData as DataOceanMetadata, keyElem.dataOceanId);
                let keysInGraph: Array<string> = [];
                let keysInMetadata: Array<string> = [];
                for (const key of keyElem.dataOceanKeys) {
                    keysInGraph.push(key.id);
                }
                for (const key of expandedKeys) {
                    keysInMetadata.push(key.id);
                }
                const diff =  keysInMetadata.filter((element) => {
                    return !keysInGraph.includes(element);
                });
                if (diff.length > 0) {
                    return SubflowInputNodeUtils.errMsgs.keysNotInSyncWithMetadata;
                }
            }
        }
    }
}

/** This file defines the on demand runbook variable mapping editor React component.  The on demand runbook variable mapping editor allows you 
 *  to edit the variables used as inputs for the on demand runbook.
 *  @module */
import { HTMLSelect, InputGroup, TextArea } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { OnDemandRunbookVariableDefinition } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import React, { useState } from "react";
import { RunbookContext } from "utils/runbooks/RunbookContext.class";
import { PrimitiveVariableType } from "utils/runbooks/VariablesUtils";
import { ProfileInterface } from "utils/services/ThirdPartyIntegrationApiService";
import "./RunbookVariableMappingEditor.scss";

/** this interface defines the properties that are passed into the RunbookVariableMappingEditor React component. */
export interface RunbookVariableMappingEditorProps {
    /** the current context of the whole runbook.  We will need the trigger context here. */
    runbookContext?: RunbookContext;
    /** a boolean value, if true this is the first row. */
    isFirstRow?: boolean;
    /** a boolean value, if true this is the last row. */
    isLastRow?: boolean;
    /** the definition of the on demand runbook variable. */
    onDemandRunbookVariable: OnDemandRunbookVariableDefinition;
    /** the default variable mapping. */
    initValue: string;
    /** the array of ProfileInterface objects with the list of auth profiles. */
    authProfiles?: ProfileInterface[];
    /** the list of Edge devices. */
    edges ?: any[];
    /** the handler for mapping changes. */
    onVariableMappingSet: (onDemandRunbookVariable: string, mappedVariable: string) => void;
}

/** Component for editing the variable mapping inside the on demand runbook inputs modal.
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the on demand runbook variable mapping editor. */
export const RunbookVariableMappingEditor = React.forwardRef((props: RunbookVariableMappingEditorProps, ref: any): JSX.Element => {
    const [authProfile, setAuthProfile] = useState(props.initValue);
    const [edge, setEdge] = useState(props.initValue);
    const variable = props.onDemandRunbookVariable;
    const name = variable.name.startsWith("runtime.") ? variable.name.substring(8, variable.name.length) : variable.name;
    const primitiveType = Object.keys(PrimitiveVariableType).filter((key) => PrimitiveVariableType[key] === variable.type)[0];
    const type = PrimitiveVariableType[primitiveType];
    const typeLabel = STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[primitiveType];

    const resources = STRINGS.runbookInvocations.onDemandVarMapping["inbound"];

    return <>
        {(props.isFirstRow || (props.isFirstRow && props.isLastRow === props.isFirstRow)) && 
        <div className="d-flex flex-row pt-2">
            <div className="d-flex flex-column pr-1 col-md-3 flex-md-grow-1">
                <label className="font-size-md-small font-weight-500 mb-2">{resources.name}</label>
            </div>
            <div className={"d-flex flex-column pl-2 pr-2 flex-md-grow-1" + (type === "json" ? " col-md-2" : " col-md-3")}>
                <label className="font-size-md-small font-weight-500 mb-2">{resources.type}</label>
            </div>
            <div className="d-flex flex-column pr-1 flex-md-grow-1 col-md-2">
                <label className="font-size-md-small font-weight-500 mb-2">{resources.unit}</label>
            </div>
            <div className={"d-flex flex-column flex-md-grow-1" + (type === "json" ? " col-md-5" : " col-md-4")}>
                <label className="font-size-md-small font-weight-500 mb-2">{resources.set}</label>
            </div>
        </div>}
        <div className={"d-flex flex-row pb-2 pt-2"}>            
            <div className="d-flex flex-column pr-1 col-md-3 flex-md-grow-1">
                <span data-testid="variable-name" className="font-size-md-small pt-2 text-break">{name}</span>
            </div>
            <div className={"d-flex flex-column pl-2 pr-2 flex-md-grow-1" + (type === "json" ? " col-md-2" : " col-md-3")}>
                <span data-testid="variable-type" className="font-size-md-small pt-2">{typeLabel}</span>
            </div>
            <div className="d-flex flex-column pr-1 flex-md-grow-1 col-md-2">
                <span data-testid="variable-unit" className="font-size-md-small pt-2">{variable.unit && variable.unit !== "none" && variable.unit !== "" ? variable.unit : "None"}</span>
            </div>
            <div className={"d-flex flex-column flex-md-grow-1" + (type === "json" ? " col-md-5" : " col-md-4")}>
                {type === PrimitiveVariableType.AUTH_PROFILE && <HTMLSelect
                    data-testid="set-variable-auth-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookInvocations.onDemandVarMapping.selectAuthenticationProfileText, value: ""}].concat(
                            props?.authProfiles?.map((profile) => {return {label: profile.name || "", value: profile.id || ""};}) || []
                        )
                    }
                    value={authProfile}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value);
                            setAuthProfile(event.currentTarget.value);
                        }
                    }
                />}
                {type === PrimitiveVariableType.ALLUVIO_EDGE && <HTMLSelect
                    data-testid="set-variable-edge-select"
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.runbookInvocations.onDemandVarMapping.selectAlluvioEdgeText, value: ""}].concat(
                            props?.edges?.map((edge) => {return {label: edge.name || "", value: edge.id || ""};}) || []
                        )
                    }
                    value={edge}
                    onChange={
                        (event) => {
                            props.onVariableMappingSet(variable.name, event.currentTarget.value);
                            setEdge(event.currentTarget.value);
                        }
                    }
                />}
                {type !== PrimitiveVariableType.AUTH_PROFILE && type !== PrimitiveVariableType.ALLUVIO_EDGE && type !== "json" && <InputGroup
                    data-testid="set-variable-text-input"
                    fill={true}
                    placeholder={STRINGS.runbookInvocations.onDemandVarMapping.staticInputPlaceHolder}
                    defaultValue={props.initValue ? props.initValue : ""}
                    onChange={
                        (event) => {
                            if (typeLabel === "Integer") {
                                const currentTargetValue = event.currentTarget.value;
                                event.currentTarget.value = currentTargetValue.replace(/\D/g,'');
                            }
                            props.onVariableMappingSet(variable.name, event.currentTarget.value);
                        }
                    }
                />}
                {type === "json" && <TextArea
                    data-testid="set-variable-text-input"
                    fill={true}
                    placeholder={STRINGS.runbookInvocations.onDemandVarMapping.staticInputPlaceHolder}
                    defaultValue={props.initValue ? props.initValue : ""}
                    onChange={
                        (event) => {
                            if (typeLabel === "Integer") {
                                const currentTargetValue = event.currentTarget.value;
                                event.currentTarget.value = currentTargetValue.replace(/\D/g,'');
                            }
                            props.onVariableMappingSet(variable.name, event.currentTarget.value);
                        }
                    }
                    className="json-additional-parameter"
                />}
            </div>
        </div>
    </>;
});

/** This module contains a utility that has various functions for handling errors that come from
 *  the API.
 *  @module
 */
import { STRINGS } from "app-strings";
import { ErrorInfo } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";


/** returns the k/v pairs with the error details. 
 *  @param error the ErrorInfo object with the error information.
 *  @returns a map of the error details values to their keys.  The keys are referred to in the 
 *      error messages in the STRINGS file. */
export function getErrorDetailsFromDalErrorInfo(error: ErrorInfo): Record<string, string> {
    const details: Record<string, string> = {};
    if (error?.innerError?.properties?.length) {
        for (const prop of error.innerError.properties) {
            // Replace any "." in the key with an __.  The localization API does not support
            // nested objects.
            details[prop.key.replace(/\./g, "__").replace(/^filter$/, "filterp")] = prop.value;
        }
    }
    return details;
}

/** Returns the formatted error message for the merge and split DAL mutations.
 *  @param error the error object returned by the catch.
 *  @param errorStrings the map of error codes to formatted strings.
 *  @param application a boolean value, if true we have an application, if false we have a location.
 *  @returns a String with the error message to display. */
export function getDalErrorMessage(error: any, errorStrings: Record<string, string>, application: boolean): string {
    let errorText = error;
    if (error?.networkError?.result?.errors?.length && error.networkError.result.errors[0].extensions?.content) {
        const errorContent = error.networkError.result.errors[0].extensions.content;
        try {
            const errorJson = JSON.parse(errorContent);
            if (errorJson?.data?.code && errorStrings[errorJson.data.code]) {
                const type: string = application ? "Application" : "Location";
                errorText = STRINGS.formatString(errorStrings[errorJson.data.code], {...(errorJson?.data?.innererror || {}), EntityType: type});
            }
        } catch (parseError) {}
    }
    return errorText;
}

/** extract any error
 *  @param error the error object from the catch.
 *  @param codes the map of error codes to formatted strings.
 *  @param defaultError the detault error to return if there is no known error code in the response.
 *  @returns a String array with any errors parsed from the response object, or the default error message. */
export function getErrorsFromApiResponse(error: any, codes: Record<string, string>, defaultError: string): string[] {
    let errors: Array<string> = [];
    const errorMsg = error?.response?.data?.code && codes[error.response.data.code]
        ? STRINGS.formatString(codes[error?.response?.data?.code], error?.response?.data?.innererror || {})
        : defaultError;
    errors.push(errorMsg);
    return errors;
}

/** This module contains the wrapper for the cloudim react-flow graph.
 *  @module
 */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactFlow, { MiniMap, Controls, OnLoadParams, Elements, ConnectionLineType } from "react-flow-renderer";
import { CloudIMGraphDef, getElements } from "utils/cloudim/TopologyUtils";
import { STRINGS } from 'app-strings';
import { IconName, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { ContextMenu2, ContextMenu2ChildrenProps } from "@blueprintjs/popover2";
import { Icon, IconNames } from "@tir-ui/react-components";
import { IconNames as BPIconNames } from "@blueprintjs/icons"
import { PROVIDER_TYPES } from "components/enums";
import { toPng, toJpeg, toSvg } from "html-to-image";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { FACET_FIELDS } from "utils/services/SearchApiService";
import { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView";

import AWSNode from "./nodes/aws/AWSNode";
import NetIMNode from "./nodes/netim/NetIMNode";
import NetIMEdge from "./edges/netim/NetIMEdge";
import LayoutControl from "./components/layout/LayoutControl";

// The default edge type to use wherever an edge is created
const defaultEdgeType: ConnectionLineType = ConnectionLineType.Bezier;

/** This interface defines the properties passed into the cloudim react-flow graph React component.*/
export interface CloudIMReactFlowGraphProps {
    /** the GraphDef object with the graph nodes and edges in it.*/
    graphDef: CloudIMGraphDef;
    regionDetail?: Region;
    showLayoutOptions?: boolean;
    showMinimap?: boolean;
    debug?: boolean;
}

/** this enum references the download types available.*/
enum DOWNLOADTYPES {
    /** Download as JSON */
    JSON = "JSON",
    /** Download as JPEG */
    JPEG = "JPEG",
    /** Download as PNG */
    PNG = "PNG",
    /** Download as SVG */
    SVG = "SVG",
}

/** Renders the cloudim react-flow graph component.
 *  @param props the properties passed in. These properties contain some of the meta data necessary to 
 *      draw the graph, and the nodes and edges that should be drawn in the graph.
 *  @returns JSX with the cloudim react flow graph component.*/
const CloudIMReactFlowGraph = React.forwardRef(({ graphDef, regionDetail, debug, ...props }: CloudIMReactFlowGraphProps, ref): JSX.Element => {
    const history = useHistory();

    const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
    const passedElements = getElements(graphDef);
    const [elements, setElements] = useState<Elements>(passedElements);

    const rerenderData = useCallback(async () => {
        setElements(getElements(graphDef));
    }, [graphDef])

    useEffect(() => {
        rerenderData().then(() => {
            if (reactFlowInstance) {
                reactFlowInstance.fitView();
            }
        });
    }, [rerenderData, reactFlowInstance]);

    const onLoad = (reactFlowInstance: OnLoadParams) => {
        setReactFlowInstance(reactFlowInstance);
        reactFlowInstance.fitView();
    };

    // istanbul ignore next
    const downloadData = (type: DOWNLOADTYPES, data: any) => {
        const fileName = (regionDetail?.displayName ?? "data").replace(/ /g, "_");
        const link = document.createElement("a");

        switch (type) {
            case DOWNLOADTYPES.JSON:
                const json = JSON.stringify(data, null, 2);
                const blob = new Blob([json], { type: "application/json" });
                const href = URL.createObjectURL(blob);
                link.href = href;
                link.download = `${fileName}.json`;
                break;
            case DOWNLOADTYPES.JPEG:
                link.href = data;
                link.download = `${fileName}.jpeg`;
                break;
            case DOWNLOADTYPES.PNG:
                link.href = data;
                link.download = `${fileName}.png`;
                break;
            case DOWNLOADTYPES.SVG:
                link.href = data;
                link.download = `${fileName}.svg`;
                break;
            default:
                break;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <ContextMenu2
            content={
                <Menu>
                    {regionDetail?.displayName && <>
                        <MenuDivider title={<span>
                            <Icon className="mr-1" icon={IconNames.MAP_MARKER} />
                            <div className="d-inline-block">{regionDetail.displayName}</div>
                        </span>} />
                        <MenuDivider />
                    </>}
                    {/* OnPrem Table View */}
                    {regionDetail?.platform === PROVIDER_TYPES.ONPREM && <MenuItem icon={BPIconNames.AREA_OF_INTEREST as IconName} text={STRINGS.cloudim.topology.contextMenu.explorerView} onClick={() => {
                        history.push(
                            getURL(getURLPath('explorer'), {
                                searchType: SEARCH_TYPE.device,
                                facets: {
                                    [FACET_FIELDS.LOCATION_NAME]: [regionDetail.code]
                                },
                            }, { replaceQueryParams: true })
                        );
                    }} />}
                    {/* AWS Table View */}
                    {regionDetail?.platform === PROVIDER_TYPES.AWS && <MenuItem icon={BPIconNames.APPLICATIONS as IconName} text={STRINGS.cloudim.topology.contextMenu.assetsView} onClick={() => {
                        history.push(
                            getURL(getURLPath('explorer'), {
                                searchType: SEARCH_TYPE.cloudassets,
                                facets: {
                                    [FACET_FIELDS.REGION]: [regionDetail.name]
                                },
                            }, { replaceQueryParams: true })
                        );
                    }} />}
                    {debug && <MenuItem icon={IconNames.DOCUMENT} text={STRINGS.cloudim.topology.contextMenu.download + " " + DOWNLOADTYPES.JSON} onClick={() => {
                        downloadData(DOWNLOADTYPES.JSON, graphDef);
                    }} />}
                    <MenuItem icon={IconNames.CAMERA} text={STRINGS.cloudim.topology.contextMenu.snapshot}>
                        <MenuItem icon={IconNames.MEDIA} text={DOWNLOADTYPES.JPEG} onClick={() => {
                            const reactFlowImage = document.querySelector('.react-flow__renderer');
                            if (reactFlowImage !== null) {
                                toJpeg(reactFlowImage as HTMLElement).then((data) => {
                                    downloadData(DOWNLOADTYPES.JPEG, data);
                                });
                            }
                        }} />
                        <MenuItem icon={IconNames.MEDIA} text={DOWNLOADTYPES.PNG} onClick={() => {
                            const reactFlowImage = document.querySelector('.react-flow__renderer');
                            if (reactFlowImage !== null) {
                                toPng(reactFlowImage as HTMLElement).then((data) => {
                                    downloadData(DOWNLOADTYPES.PNG, data);
                                });
                            }
                        }} />
                        <MenuItem icon={IconNames.MEDIA} text={DOWNLOADTYPES.SVG} onClick={() => {
                            const reactFlowImage = document.querySelector('.react-flow__renderer');
                            if (reactFlowImage !== null) {
                                toSvg(reactFlowImage as HTMLElement).then((data) => {
                                    downloadData(DOWNLOADTYPES.SVG, data);
                                });
                            }
                        }} />
                    </MenuItem>
                </Menu>
            }
        >
            {(ctxMenuProps: ContextMenu2ChildrenProps) => (
                <ReactFlow
                    elements={elements as Elements<any>}
                    nodesConnectable={false}
                    nodeTypes={{
                        awsNode: AWSNode,
                        netimNode: NetIMNode
                    }}
                    edgeTypes={{
                        netimEdge: NetIMEdge
                    }}
                    connectionLineType={defaultEdgeType}
                    onLoad={onLoad}
                    minZoom={0}
                    maxZoom={2.5}
                    onPaneContextMenu={(e) => { ctxMenuProps.onContextMenu(e as React.MouseEvent<HTMLElement, MouseEvent>) }}
                >
                    <LayoutControl
                        showLayoutOptions={props.showLayoutOptions}
                        elements={elements as Elements<any>}
                        updateElements={(elements) => setElements(elements)}
                        reactFlowInstance={reactFlowInstance}
                        debug={false}
                        ref={ref}
                    />
                    {ctxMenuProps.popover}
                    {props.showMinimap && <MiniMap nodeBorderRadius={20} />}
                    <Controls showZoom={true} showFitView={true} showInteractive={true} />
                </ReactFlow>
            )}
        </ContextMenu2>
    );
});

export { CloudIMReactFlowGraph };
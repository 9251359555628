/** This file defines the subflow editor React component.  The subflow editor allows you 
 *  to edit the configuration of a subflow.
 *  @module */
import React, { useCallback, useEffect, useState, useContext, useMemo, useRef } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { UniversalNode } from "../../UniversalNode";
import { RunbookContextSummary } from "../RunbookContextSummary";
import { DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { useStateSafePromise } from "@tir-ui/react-components";
import { NodeLibraryNode, NodeSubflowVariableDefinition } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { IS_EMBEDDED, SHOW_CONTEXT } from "components/enums/QueryParams";
import { SubflowVariableMappingEditor, Method, VariableMapping } from "./SubflowVariableMappingEditor";
import { ProfileInterface, ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService";
import { useQuery } from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { DURATION, durationToRoundedTimeRange } from "utils/stores/GlobalTimeStore";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { RunbookContext } from "utils/runbooks/RunbookContext.class";
import { DIRECTION, NodeDef, NodeWiresSetting, Variant } from "../../types/GraphTypes";
import { isSubflowInputNode, runbookService } from 'utils/runbooks/RunbookUtils';
import { RunbookConfig } from "utils/services/RunbookApiService";
import { getURLPath } from "config";
import { startCase, toLower, uniq } from 'lodash';
import { getGraphDefFromRunbookConfig } from 'pages/create-runbook/views/create-runbook/CreateRunbookView';
import SubflowNodeLibrary from "pages/create-runbook/views/create-runbook/subflow_node_library.json";
import { NodeLibrary, NodeLibrarySpec } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { VariableContext } from 'utils/runbooks/VariableContext';
import { PrimitiveVariableType, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { Icon } from "@tir-ui/react-components";
import { Button, Callout, Intent, PopoverPosition } from '@blueprintjs/core';
import { Classes, Tooltip2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { Version } from "utils/Version.class";
import { SuccessToaster } from '@tir-ui/react-components';
import { APP_ICONS } from 'components/sdwan/enums';
import { ALLOWED_VALUES } from "../subflow-input/SubflowInputNodeEditor";
import './SubflowNodeEditor.scss';

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface SubflowNodeProperties {
    /** a string with the id of the subflow node. */
    configurationId?: string;
    /** the incomming variable mappings. */
    in?: VariableMapping[];
    /** the outgoing variable mappings. */
    out?: VariableMapping[];
}

/** Component for editing the properties of a subflow node
 *  @param selectedNode - Currently selected active subflow node
 *  @param libraryNode- Selected transform node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the subflow input node editor. */
export const SubflowNodeEditor = React.forwardRef((
    { selectedNode, libraryNode, graphDef, subflows, onRuntimeOrSubflowVariableEdited, handleChange }: SimpleNodeEditorProps, ref: any
): JSX.Element => {
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [executeSafely] = useStateSafePromise();
    const [subflowId, setSubflowId] = useState<string | undefined>(libraryNode?.subflowId);
    const [subflowRunbook, setSubflowRunbook] = useState<RunbookConfig>();
    const [subflowInputTypeDesc, setSubflowInputTypeDesc] = useState<string>(); 
    const [subflowOutputTypeDesc, setSubflowOutputTypeDesc] = useState<string[]>();
    const [runtimeVariablesToBeCreated, setRuntimeVariablesToBeCreated] = useState<string[]>();
    const [assignedAutomaticallyCreatedRuntimeValue, setAssignedAutomaticallyCreatedRuntimeValue] = useState<boolean>(false);
    const [showAdvancedInputVariables, setShowAdvancedInputVariables] = useState<boolean>(false);
    const [showAdvancedOutputVariables, setShowAdvancedOutputVariables] = useState<boolean>(false);

    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(SubflowNodeLibrary as NodeLibrarySpec));

    const hasOutboundDescription = useRef<boolean>(false);

    const { getVariables, syncRuntimeOrSubflowVariables } = useContext(VariableContext);

    // Component states
    const [curProperties, setCurProperties] = useState<SubflowNodeProperties>({
        configurationId: selectedNode?.getProperty("configurationId") ? selectedNode?.getProperty("configurationId") : "",
        in: selectedNode?.getProperty("in") ? JSON.parse(JSON.stringify(selectedNode?.getProperty("in"))) : [],
        out: selectedNode?.getProperty("out") ? JSON.parse(JSON.stringify(selectedNode?.getProperty("out"))) : []
    });

    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});

    const customProperties = useContext(CustomPropertyContext);

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    const [authProfiles, setAuthProfiles] = useState<ProfileInterface[]>();
    const fetchProfiles = useCallback(() => {
        return executeSafely(ThirdPartyIntegrationService.getRunbookAndIntegrationAuthProfiles()).then(
            (response: ProfileInterface[]) => {
                setAuthProfiles(response.filter(profile => profile.isEnabled));
            },
            _ => {
                setAuthProfiles([]);
            }
        );
    }, [executeSafely]);

    useEffect(() => {
        // Fetch profiles on load.
        fetchProfiles();
    }, [fetchProfiles]);

    const fetchSubflowRunbook = useCallback(() => {
        if (subflowId) {
            return executeSafely(runbookService.getRunbook(subflowId)).then((subflow) => {
                setSubflowRunbook(subflow);
            });
        }
    }, [executeSafely, subflowId]);

    useEffect(() => {
        fetchSubflowRunbook();
    }, [fetchSubflowRunbook]);

    useEffect(() => {
        if (subflowRunbook?.nodes) {
            let acceptedDataFormat;
            let acceptedEntity;
            for (const subflowNode of subflowRunbook.nodes) {
                if (subflowNode.type === "subflow_input") {
                    if (subflowNode?.properties?.synthKeys?.[0]?.label) {
                        acceptedEntity = subflowNode.properties.synthKeys[0].label;
                        let hasVowel = false;
                        if (['a','e','i','o','u'].includes(acceptedEntity[0].toLowerCase())) {
                            hasVowel = true;
                        }
                        acceptedDataFormat = subflowNode.properties?.outputDataFormat;
                        setSubflowInputTypeDesc(`This subflow accepts a${hasVowel ? 'n' : ''} ${acceptedEntity} entity ${acceptedEntity === "JSON" ? "" : acceptedDataFormat ? `in a ${acceptedDataFormat} data format` : ''} from the previous node.`);
                        break;
                    } else if (subflowNode?.properties?.outputDataFormat) {
                        setSubflowInputTypeDesc(`${STRINGS.runbookEditor.nodeEditor.subflowInputTypeEmpty} The accepted input data format is ${subflowNode?.properties?.outputDataFormat}.`)
                        break;
                    }
                }
            }
            let subflowHasOutputNode = false;
            const returnedEntitiesDesc: string[] = [];
            for (const subflowNode of subflowRunbook.nodes) {
                if (subflowNode.type === "subflow_output") {
                    subflowHasOutputNode = true;
                    const graphDef = getGraphDefFromRunbookConfig(nodeLibrary.current, subflowRunbook);
                    const context = new RunbookContext(
                        subflowNode as NodeDef, graphDef, DataOceanUtils.dataOceanMetaData
                    );
                    const subflowOutputTypeAndFormat = getSubflowOutputTypeAndFormat(subflowRunbook.nodes, context);
                    let subflowOutputFormat = subflowOutputTypeAndFormat.subflowOutputFormat;
                    let hasVowel = false;
                    let returnedEntityDesc;
                    if (subflowOutputTypeAndFormat.subflowOutputType) {
                        if (['a','e','i','o','u'].includes(subflowOutputTypeAndFormat.subflowOutputType[0].toLowerCase())) {
                            hasVowel = true;
                        }
                        returnedEntityDesc = `The subflow node returns a${hasVowel ? 'n' : ''} ${subflowOutputTypeAndFormat.subflowOutputType} entity`;
                    } else {
                        if (acceptedEntity && acceptedDataFormat && context.triggerContext?.source.type === "subflow_input") {
                            if (['a','e','i','o','u'].includes(acceptedEntity[0].toLowerCase())) {
                                hasVowel = true;
                            }
                            returnedEntityDesc = `The subflow node returns a${hasVowel ? 'n' : ''} ${acceptedEntity} entity`;
                            subflowOutputFormat = acceptedEntity === "JSON" ? "" : acceptedDataFormat;
                        } else {
                            returnedEntityDesc = STRINGS.runbookEditor.nodeEditor.subflowOutputUnknownEntity;
                        }
                    }
                    returnedEntitiesDesc.push(`${returnedEntityDesc} ${acceptedEntity === "JSON" ? "" : subflowOutputFormat ? `in a ${subflowOutputFormat} data format` : 'in an unknown data format'} to the next node.`);
                    setSubflowOutputTypeDesc(uniq(returnedEntitiesDesc));
                }
            }
            if (!subflowHasOutputNode) {
                returnedEntitiesDesc.push(STRINGS.runbookEditor.nodeEditor.subflowNoOutputNode);
                setSubflowOutputTypeDesc(returnedEntitiesDesc);
            }
        } else {
            setSubflowInputTypeDesc(STRINGS.runbookEditor.nodeEditor.subflowNoInputNode);
            setSubflowOutputTypeDesc([STRINGS.runbookEditor.nodeEditor.subflowNoOutputNode])
        }
    }, [subflowRunbook, subflowId]);

    const {inputVariables, outputVariables, staticInputValuesLists, subflowName, connectorTagsMap} = useMemo(() => {
        let inputVariables: NodeSubflowVariableDefinition[] = [];
        let outputVariables: NodeSubflowVariableDefinition[] = [];
        let staticInputValuesLists: any = {};
        let subflowName = libraryNode?.subflowName;
        let connectorTagsMap: any = {};

        if (subflowRunbook) {
            subflowName = subflowRunbook.name;
            const subflowVariablesDefs = subflowRunbook.subflowVariables || {primitiveVariables: [], structuredVariables: []};
            for (const node of subflowRunbook.nodes || []) {
                if (isSubflowInputNode(node)) {
                    if (node.properties?.inputVariables?.length) {
                        for (const inputVariable of node.properties.inputVariables) {
                            for (const primitiveVariable of subflowVariablesDefs.primitiveVariables) {
                                if (primitiveVariable.name === inputVariable) {
                                    inputVariables.push({name: inputVariable, type: primitiveVariable.type, unit: primitiveVariable.unit});
                                }
                            }
                            for (const structuredVariable of subflowVariablesDefs.structuredVariables) {
                                if (structuredVariable.name === inputVariable) {
                                    inputVariables.push({name: inputVariable, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries});
                                }
                            }
                        }
                    }
                    if (node.properties.outputVariables?.length) {
                        const variables: NodeSubflowVariableDefinition[] = [];
                        for (const outputVariable of node.properties.outputVariables) {
                            for (const primitiveVariable of subflowVariablesDefs.primitiveVariables) {
                                if (primitiveVariable.name === outputVariable) {
                                    variables.push({name: outputVariable, type: primitiveVariable.type, unit: primitiveVariable.unit});
                                }
                            }
                            for (const structuredVariable of subflowVariablesDefs.structuredVariables) {
                                if (structuredVariable.name === outputVariable) {
                                    variables.push({name: outputVariable, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries});
                                }
                            }
                        }
                        outputVariables = variables;
                    }

                    connectorTagsMap = node.properties?.tagIds || {};
                    staticInputValuesLists = Object.values(node.properties?.staticInputValuesLists || {})?.length ? node.properties.staticInputValuesLists : {};
                }
            }
        }
        return {inputVariables, outputVariables, staticInputValuesLists, subflowName, connectorTagsMap};
    }, [subflowRunbook, libraryNode?.subflowName]);

    useEffect(() => {
        const runtimeVariables = getVariables(RUNTIME_SCOPE);
        const runtimeVariablesToBeCreated: Array<string> = [];
        if (outputVariables.length) {
            outputVariables.forEach(variable => {
                const newRuntimeVariableName = variable.name.replace('subflow.','runtime.');
                const newRuntimeVariableType = variable.type;
                if (!runtimeVariables.primitiveVariables.find(variable => variable.name === newRuntimeVariableName && variable.type === newRuntimeVariableType) && Object.values(PrimitiveVariableType).includes(variable.type as PrimitiveVariableType)) {
                    runtimeVariables.primitiveVariables.push({
                        name: newRuntimeVariableName, type: variable.type as PrimitiveVariableType, isReadOnly: false
                    });
                    runtimeVariablesToBeCreated.push(newRuntimeVariableName);
                }
            });
            setRuntimeVariablesToBeCreated(runtimeVariablesToBeCreated);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outputVariables]);

    const { data } = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('./edge-config-list.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
    });

    const runbookContext = useMemo<RunbookContext | undefined>(() => {
        if (selectedNode && graphDef && objMetricMetaData) {
            return new RunbookContext(
                selectedNode?.node as NodeDef, graphDef, objMetricMetaData, customProperties
            );
        }
    }, [selectedNode, graphDef, objMetricMetaData, customProperties])

    /** Partially update the state of the current properties
     *  @param partialProps -  transform node properties that need to be updated. */
    /*
    function syncProperties(partialProps: SubflowNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }
    */

    /** Update the selected node properties. This is invoked when done button is clicked
     *  on the node editor dialog.
     *  @param properties -  Properties in selected node that need to updated
     *  @param selectedNode - node being editied
     *  @param libraryNode - object that specifies all the editable properties for a given node. */
    function updateNode(properties: SubflowNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {      
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty("configurationId", curProperties?.configurationId);
        selectedNode.setProperty("in", curProperties?.in);
        selectedNode.setProperty("out", curProperties?.out);

        // We need to update the outputs count
        const wires: NodeWiresSetting | null | undefined = selectedNode.getWires();
        if (wires && subflowRunbook && subflows) {
            const subflowDef = subflows.find(subflow => subflow.id === subflowRunbook.id);
            if (subflowDef) {
                wires.direction = DIRECTION.NONE;
                wires.in = subflowDef.in;
                wires.inputLabels = subflowDef.inputLabels;
                wires.out = subflowDef.out;
                wires.outputLabels = subflowDef.outputLabels
                if ((subflowDef?.in?.length || 0) > 0 && (subflowDef?.out?.length || 0) > 0) {
                    wires.direction = DIRECTION.BOTH;
                } else if ((subflowDef?.in?.length || 0) > 0) {
                    wires.direction = DIRECTION.IN;
                } else if ((subflowDef?.out?.length || 0) > 0) {
                    wires.direction = DIRECTION.OUT;
                }        
            }
        }
    }

    function getNodeProperties(properties: SubflowNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        const outputProperties = {};
        outputProperties["configurationId"] = curProperties?.configurationId;
        outputProperties["in"] = curProperties?.in;
        outputProperties["out"] = curProperties?.out;

        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            /*
            SubflowInputNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            */

            const checkIfHasEmptyRequiredFields = (contextValues) => {
                let hasEmptyRequiredFields = false;
                if (contextValues) {
                    contextValues.forEach((item, _index) => {
                        const variableAttributes = inputOrOutputValuesDescriptions?.find(
                            (item2: { valueName: string, description: string }) => item2.valueName === item.inner
                        );
                        const isRequired = variableAttributes?.required;
                        const validateWithRegex = variableAttributes?.validateWithRegex;
                        let regex;
                        if (validateWithRegex && variableAttributes?.valueType === ALLOWED_VALUES.FROM_REGULAR_EXPRESSION) {
                            let isValidRegex = true;
                            try {
                                regex = new RegExp(validateWithRegex);
                            } catch(e) {
                                isValidRegex = false;
                            }
                            if (isValidRegex && !regex.test(item.outer)) {
                                errorMessages.push(`The ${item.inner} context value failed regex validation.`);
                            }
                        }
                        if (isRequired && !item.outer) {
                            errorMessages.push(`The ${item.inner} context value is required.`);
                        }
                        if (variableAttributes?.valueType === ALLOWED_VALUES.BETWEEN && variableAttributes?.minValue && variableAttributes?.maxValue && (+item.outer < variableAttributes?.minValue || +item.outer > variableAttributes?.maxValue)) {
                            errorMessages.push(`The ${item.inner} context value is not between ${variableAttributes?.minValue} and ${variableAttributes?.maxValue}.`);
                        }
                    });
                }
                return hasEmptyRequiredFields;
            }

            checkIfHasEmptyRequiredFields(curProperties.in);
            checkIfHasEmptyRequiredFields(curProperties.out);

            if (curProperties.in?.length) {
                curProperties.in.forEach(item => {
                    const variableType = inputVariables?.find(inputVariable => inputVariable.name === item.inner)?.type;
                    if (variableType === PrimitiveVariableType.CONNECTOR && !item.outer) {
                        errorMessages.push(`The ${item.inner} context value is required.`);
                    } 
                });
            }

            return errorMessages;
        }
    };

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData || !authProfiles || !data?.edges || (subflowId && !subflowRunbook)) {
        return <tr><td colSpan={2}><DataLoadFacade loading /></td></tr>;
    }

    const inputOrOutputValuesDescriptions = 
        subflowRunbook?.nodes?.find(
            node => node.type === "subflow_input"
        )?.properties?.inputOrOutputValuesDescriptions;
    
    if (inputOrOutputValuesDescriptions?.length) {
        inputOrOutputValuesDescriptions.forEach(item => {
            if (item.description?.trim() && curProperties.out?.find(property => property.inner === item.valueName)) {
                hasOutboundDescription.current = true;
            }
        });
    }    

    let showVersionWarning: boolean = false;
    let currentVersion: Version = Version.parse(subflowRunbook?.version || "");
    let latestVersion: Version = new Version(0, 0, 0);
    subflowRunbook?.otherVersions?.forEach(versionInfo => {
        let version: Version = Version.parse(versionInfo.version || "");
        if (version.isGreaterThan(currentVersion)) {
            showVersionWarning = true;
            latestVersion = version;
        }
    });

    const advancedInputVariables = inputVariables.filter((variable, _index) => {
        const variableAttributes = inputOrOutputValuesDescriptions?.find(
            (item: { valueName: string, description: string }) => item.valueName === variable.name
        );
        const isAdvanced = variableAttributes?.advanced;
        if (isAdvanced) return true;
        return false;
    });

    const advancedOutputVariables = outputVariables.filter((variable, _index) => {
        const variableAttributes = inputOrOutputValuesDescriptions?.find(
            (item: { valueName: string, description: string }) => item.valueName === variable.name
        );
        const isAdvanced = variableAttributes?.advanced;
        if (isAdvanced) return true;
        return false;
    });

    return (
        <>
            <BasicDialog dialogState={dialogState} className="transform-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            {showVersionWarning && <tr><td colSpan={2}>
                <Callout intent={Intent.WARNING} className="edit-subflow-warning">
                    {STRINGS.formatString(
                        STRINGS.runbookEditor.nodeEditor.subflowUpdateVersionText, 
                        {oldVersion: currentVersion.toString(), newVersion: latestVersion.toString()}
                    )}
                    <Button className="ml-1" onClick={() => {
                        let newVersion: Version = Version.parse(subflowRunbook?.version || "");
                        let configId = curProperties.configurationId;
                        subflowRunbook?.otherVersions?.forEach(versionInfo => {
                            let version: Version = Version.parse(versionInfo.version || "");
                            if (version.isGreaterThan(newVersion)) {
                                newVersion = version;
                                configId = versionInfo.id;
                            }
                        });
                        setSubflowId(configId);
                        setSubflowRunbook(undefined);
                        setCurProperties({ ...curProperties, ...{configurationId: configId} });
                        if (handleChange) {
                            handleChange();
                        }
                    }}>{STRINGS.runbookEditor.nodeEditor.subflowUpdateVersionButton}</Button>
                </Callout>
            </td></tr>}
            <tr>
                <td colSpan={2}>
                    <TabbedSubPages renderActiveTabPanelOnly={true} className="subflow-editor-tabs">
                        <Tab id="subflow-input" title={STRINGS.runbookEditor.nodeEditor.subflowInput} className="pt-3">
                            <table><tbody>
                                <tr><td className="font-size-md-large font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.subflowInputType}</td></tr>
                                {!!subflowInputTypeDesc && (<tr><td className="display-8 pt-3 pb-3" colSpan={2}><Callout intent={Intent.PRIMARY}>{subflowInputTypeDesc}</Callout></td></tr>)}
                                <tr><td className="font-size-md-large font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.subflowInputContext}</td></tr>
                                <tr><td className="display-8 pt-3 pb-3" colSpan={2}><Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowInputContextDesc}</Callout></td></tr>
                                <tr>
                                    <td className="display-9 pt-2" colSpan={2}>
                                        {inputVariables.filter((variable, _index) => {
                                            const variableAttributes = inputOrOutputValuesDescriptions?.find(
                                                (item: { valueName: string, description: string }) => item.valueName === variable.name
                                            );
                                            const isAdvanced = variableAttributes?.advanced;
                                            if (isAdvanced) return false;
                                            return true;
                                        }).map((variable, index) => {
                                            const prop = curProperties.in?.find((item) => item.inner === variable.name);
                                            const defaultValue = subflowRunbook?.subflowVariables?.primitiveVariables?.find(item => item.name === variable.name)?.defaultValue;
                                            return <SubflowVariableMappingEditor 
                                                key={"variable-editor-" + variable.name}
                                                runbookContext={runbookContext}
                                                isFirstRow={index === 0} inbound={true} subflowVariable={variable} 
                                                isLastRow={index === inputVariables.length - 1}
                                                initValue={prop ? prop : {inner: variable.name, outer: "", method: Method.STATIC}} 
                                                authProfiles={authProfiles} edges={data?.edges}
                                                connectorTagsMap={connectorTagsMap}
                                                onVariableMappingSet={(subflowVariable: string, mappedVariable: string, method: Method) => {
                                                    const prop = curProperties.in?.find((item) => item.inner === subflowVariable);
                                                    const inputVariable = inputVariables.find((item) => item.name === subflowVariable);
                                                    if (prop) {
                                                        prop.outer = mappedVariable;
                                                        prop.method = method;
                                                        prop.isUnset = method === Method.UNSET;
                                                    } else if (inputVariable) {
                                                        curProperties?.in?.push({
                                                            inner: subflowVariable,
                                                            outer: mappedVariable,
                                                            method
                                                        })
                                                    }
                                                }}
                                                integrationId={libraryNode?.integrationId}
                                                staticInputValuesLists={staticInputValuesLists}
                                                inputOrOutputValuesDescriptions={inputOrOutputValuesDescriptions}
                                                defaultValue={defaultValue}
                                            />;
                                        })}
                                        {advancedInputVariables?.length > 0 && <div className="display-8 font-weight-bold pt-2 pb-3">
                                            <div className="d-inline-block clickable"
                                                onClick={() => {
                                                    setShowAdvancedInputVariables(!showAdvancedInputVariables);
                                                }}
                                            >
                                                <Icon data-testid="show-advanced-section" className="align-middle" icon={showAdvancedInputVariables ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                                                <span>{STRINGS.runbookEditor.nodeEditor.runbookContext.advancedSection}</span>
                                            </div>
                                        </div>}
                                        {showAdvancedInputVariables && advancedInputVariables.map((variable, index) => {
                                            const prop = curProperties.in?.find((item) => item.inner === variable.name);
                                            const defaultValue = subflowRunbook?.subflowVariables?.primitiveVariables?.find(item => item.name === variable.name)?.defaultValue;
                                            return <SubflowVariableMappingEditor 
                                                key={"variable-editor-" + variable.name}
                                                runbookContext={runbookContext}
                                                isFirstRow={index === 0} inbound={true} subflowVariable={variable} 
                                                isLastRow={index === inputVariables.length - 1}
                                                initValue={prop ? prop : {inner: variable.name, outer: "", method: Method.STATIC}} 
                                                authProfiles={authProfiles} edges={data?.edges}
                                                onVariableMappingSet={(subflowVariable: string, mappedVariable: string, method: Method) => {
                                                    const prop = curProperties.in?.find((item) => item.inner === subflowVariable);
                                                    const inputVariable = inputVariables.find((item) => item.name === subflowVariable);
                                                    if (prop) {
                                                        prop.outer = mappedVariable;
                                                        prop.method = method;
                                                    } else if (inputVariable) {
                                                        curProperties?.in?.push({
                                                            inner: subflowVariable,
                                                            outer: mappedVariable,
                                                            method
                                                        })
                                                    }
                                                }}
                                                integrationId={libraryNode?.integrationId}
                                                staticInputValuesLists={staticInputValuesLists}
                                                inputOrOutputValuesDescriptions={inputOrOutputValuesDescriptions}
                                                defaultValue={defaultValue}
                                            />;
                                        })}
                                    </td>
                                </tr>
                            </tbody></table>
                        </Tab>
                        <Tab id="subflow-output" title={STRINGS.runbookEditor.nodeEditor.subflowOutput} className="pt-3 subflow-output">
                            <table><tbody>
                                <tr><td className="font-size-md-large font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.subflowOutputType}</td></tr>
                                {subflowOutputTypeDesc?.length && <tr><td className="display-8 pt-3 pb-3" colSpan={2}>{
                                    subflowOutputTypeDesc.length > 1 ? <ol className="mb-0 pb-0">{subflowOutputTypeDesc.map((desc, index) => <li key={index}>{desc}</li>)}</ol> : <Callout intent={Intent.PRIMARY}>{subflowOutputTypeDesc[0]}</Callout>
                                }</td></tr>}
                                <tr><td className="font-size-md-large font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.subflowOutputContext}</td></tr>
                                <tr><td className="display-8 pt-3 pb-3" colSpan={2}><Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowOutputContextDesc}</Callout></td></tr>
                                {outputVariables.length > 0 && <tr>
                                    <td colSpan={2}>
                                        <Button 
                                            className="mb-1"
                                            intent={Intent.SUCCESS}
                                            onClick={() => {
                                            const runtimeVariables = getVariables(RUNTIME_SCOPE);
                                            if (onRuntimeOrSubflowVariableEdited) {
                                                outputVariables.forEach(variable => {
                                                    const newRuntimeVariableName = variable.name.replace('subflow.','runtime.');
                                                    const newRuntimeVariableType = variable.type;
                                                    if (!runtimeVariables.primitiveVariables.find(variable => variable.name === newRuntimeVariableName && variable.type === newRuntimeVariableType) && Object.values(PrimitiveVariableType).includes(variable.type as PrimitiveVariableType)) {
                                                        runtimeVariables.primitiveVariables.push({
                                                            name: newRuntimeVariableName, type: variable.type as PrimitiveVariableType, isReadOnly: false
                                                        });
                                                    }
                                                });
                                                syncRuntimeOrSubflowVariables(runtimeVariables);
                                                onRuntimeOrSubflowVariableEdited(runtimeVariables);
                                            }
                                            setAssignedAutomaticallyCreatedRuntimeValue(true);
                                            if (handleChange) {
                                                handleChange();
                                            }
                                            SuccessToaster({message: STRINGS.runbookEditor.nodeEditor.subflowOutputAssignSuccessful})
                                        }}>{STRINGS.runbookEditor.nodeEditor.subflowOutputAssignRuntimeVariablesButton}</Button>
                                        <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 ml-2"} intent="primary" 
                                            content={
                                                <div style={{maxWidth: "200px"}}>{STRINGS.runbookEditor.nodeEditor.subflowOutputAssignRuntimeVariablesTooltip}</div>
                                            } interactionKind="hover" position={PopoverPosition.RIGHT}>
                                            <Icon className="text-secondary" icon={IconNames.HELP} />
                                        </Tooltip2>
                                    </td>
                                </tr>}
                                <tr>
                                    <td className="display-9 pt-2" colSpan={2}>
                                        {outputVariables.filter((variable, _index) => {
                                            const variableAttributes = inputOrOutputValuesDescriptions?.find(
                                                (item: { valueName: string, description: string }) => item.valueName === variable.name
                                            );
                                            const isAdvanced = variableAttributes?.advanced;
                                            if (isAdvanced) return false;
                                            return true;
                                        }).map((variable, index) => {
                                            const prop = curProperties.out?.find((item) => item.inner === variable.name);
                                            const defaultValue = subflowRunbook?.subflowVariables?.primitiveVariables?.find(item => item.name === variable.name)?.defaultValue;
                                            return <SubflowVariableMappingEditor 
                                                key={"variable-editor-" + variable.name}
                                                runbookContext={runbookContext}
                                                isFirstRow={index === 0} inbound={false} subflowVariable={variable} 
                                                isLastRow={index === outputVariables.length - 1}
                                                initValue={prop ? prop : {inner: variable.name, outer: "", method: Method.STATIC}} authProfiles={authProfiles}
                                                onVariableMappingSet={(subflowVariable: string, mappedVariable: string, method: Method) => {
                                                    const prop = curProperties.out?.find((item) => item.inner === subflowVariable);
                                                    const outputVariable = outputVariables.find((item) => item.name === subflowVariable);
                                                    if (prop) {
                                                        prop.outer = mappedVariable;
                                                        prop.method = method;
                                                    } else if (outputVariable) {
                                                        curProperties?.out?.push({
                                                            inner: subflowVariable,
                                                            outer: mappedVariable,
                                                            method
                                                        })
                                                    }
                                                }}
                                                assignedAutomaticallyCreatedRuntimeValue={assignedAutomaticallyCreatedRuntimeValue}
                                                runtimeVariablesToBeCreated={runtimeVariablesToBeCreated}
                                                onRuntimeOrSubflowVariableEdited={onRuntimeOrSubflowVariableEdited}
                                                setAssignedAutomaticallyCreatedRuntimeValue={setAssignedAutomaticallyCreatedRuntimeValue}
                                                hasOutboundDescription={hasOutboundDescription.current}
                                                inputOrOutputValuesDescriptions={inputOrOutputValuesDescriptions}
                                                defaultValue={defaultValue}
                                            />;
                                        })}
                                        {advancedOutputVariables?.length > 0 && <div className="display-8 font-weight-bold pt-2 pb-3">
                                            <div className="d-inline-block clickable"
                                                onClick={() => {
                                                    setShowAdvancedOutputVariables(!showAdvancedOutputVariables);
                                                }}
                                            >
                                                <Icon data-testid="show-advanced-section" className="align-middle" icon={showAdvancedOutputVariables ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                                                <span>{STRINGS.runbookEditor.nodeEditor.runbookContext.advancedSection}</span>
                                            </div>
                                        </div>}
                                        {showAdvancedOutputVariables && advancedOutputVariables.map((variable, index) => {
                                            const prop = curProperties.out?.find((item) => item.inner === variable.name);
                                            const defaultValue = subflowRunbook?.subflowVariables?.primitiveVariables?.find(item => item.name === variable.name)?.defaultValue;
                                            return <SubflowVariableMappingEditor 
                                                key={"variable-editor-" + variable.name}
                                                runbookContext={runbookContext}
                                                isFirstRow={index === 0} inbound={false} subflowVariable={variable} 
                                                isLastRow={index === outputVariables.length - 1}
                                                initValue={prop ? prop : {inner: variable.name, outer: "", method: Method.STATIC}} authProfiles={authProfiles}
                                                onVariableMappingSet={(subflowVariable: string, mappedVariable: string, method: Method) => {
                                                    const prop = curProperties.out?.find((item) => item.inner === subflowVariable);
                                                    const outputVariable = outputVariables.find((item) => item.name === subflowVariable);
                                                    if (prop) {
                                                        prop.outer = mappedVariable;
                                                        prop.method = method;
                                                    } else if (outputVariable) {
                                                        curProperties?.out?.push({
                                                            inner: subflowVariable,
                                                            outer: mappedVariable,
                                                            method
                                                        })
                                                    }
                                                }}
                                                assignedAutomaticallyCreatedRuntimeValue={assignedAutomaticallyCreatedRuntimeValue}
                                                runtimeVariablesToBeCreated={runtimeVariablesToBeCreated}
                                                onRuntimeOrSubflowVariableEdited={onRuntimeOrSubflowVariableEdited}
                                                setAssignedAutomaticallyCreatedRuntimeValue={setAssignedAutomaticallyCreatedRuntimeValue}
                                                hasOutboundDescription={hasOutboundDescription.current}
                                                inputOrOutputValuesDescriptions={inputOrOutputValuesDescriptions}
                                                defaultValue={defaultValue}
                                            />;
                                        })}
                                    </td>
                                </tr>
                            </tbody></table>
                        </Tab>
                    </TabbedSubPages>
                </td>
            </tr>
            {/*showVersionWarning && <tr><td colSpan={2}><div className="mb-2">
                {STRINGS.formatString(
                    STRINGS.runbookEditor.nodeEditor.subflowUpdateVersionText, 
                    {oldVersion: currentVersion.toString(), newVersion: latestVersion.toString()}
                )}
                <Button className="mb-1" onClick={() => {
                    let newVersion: Version = Version.parse(subflowRunbook?.version || "");
                    let configId = curProperties.configurationId;
                    subflowRunbook?.otherVersions?.forEach(versionInfo => {
                        let version: Version = Version.parse(versionInfo.version || "");
                        if (version.isGreaterThan(newVersion)) {
                            newVersion = version;
                            configId = versionInfo.id;
                        }
                    });
                    setSubflowId(configId);
                    setSubflowRunbook(undefined);
                    setCurProperties({ ...curProperties, ...{configurationId: configId} });
                    if (handleChange) {
                        handleChange();
                    }
                }}>{STRINGS.runbookEditor.nodeEditor.subflowUpdateVersionButton}</Button>
                <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 ml-2"} intent="primary" 
                    content={
                        <div style={{maxWidth: "200px"}}>{STRINGS.runbookEditor.nodeEditor.subflowUpdateVersionTooltip}</div>
                    } interactionKind="hover" position={PopoverPosition.RIGHT}>
                    <Icon className="text-secondary" icon={IconNames.HELP} />
                </Tooltip2>
            </div></td></tr>*/}
            {!IS_EMBEDDED && !libraryNode?.subflowBuiltIn && <tr><td colSpan={2}><a className="p-1 text-nowrap" 
                    href={getURLPath('create-runbook') + '?fid=' + curProperties.configurationId + '&fname=' + subflowName + '&variant=' + Variant.SUBFLOW} 
                    target='_blank' rel="noreferrer"
                >{STRINGS.runbookEditor.nodeEditor.subflow.edit}</a>
                <Callout intent={Intent.WARNING} className="edit-subflow-warning mt-3">
                    {STRINGS.runbookEditor.nodeEditor.subflow.editWarning}
                </Callout>
            </td></tr>}
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});

/** Returns an object containing the subflow output entity type and format. 
 *  @param nodes the nodes contained in the subflow runbook.
 *  @param context the subflow output node runbook context. */
export function getSubflowOutputTypeAndFormat(nodes, context: RunbookContext) {   
    let subflowOutputType: string | undefined;
    let subflowOutputFormat: string | undefined;
    let getSubflowOutputFormatFromSubflowInput = false;
    let subflowOutputContextLast: any;
    if (context?.nodeContexts.length) {
        subflowOutputContextLast = context.nodeContexts[context.nodeContexts.length-1];
        const subflowOutputContextReversed = context.nodeContexts.reverse();
        loopSubflowOutputContextReversed: for (const subflowOutputContext of subflowOutputContextReversed) {
            const nodeId = subflowOutputContext.source.id;
            for (const node of nodes) {
                if (node.id === nodeId && (["aggregator", "transform", "http", "data_ocean"].includes(node.type))) {
                    if (node.type === "aggregator") {
                        if (node?.properties) {
                            const groupBy = Array.isArray(node.properties) ? node.properties.find(item => item.key === 'groupBy') : node.properties.groupBy;
                            subflowOutputType = groupBy?.value?.length ? 'Custom aggregation by ' + groupBy.value.join(', ') : groupBy?.length ? 'Custom aggregation by ' + groupBy.join(', ') : 'Custom aggregation';
                        }
                    } else if (node.type === "transform") {
                        if (node?.properties) {
                            let useVariableDefinition = Array.isArray(node.properties) ? node.properties.find(item => item.key === 'useVariableDefinition') : node.properties.useVariableDefinition;
                            useVariableDefinition = useVariableDefinition?.value || useVariableDefinition;
                            subflowOutputType = useVariableDefinition ? useVariableDefinition.replace('subflow.','') : 'Custom';
                        }
                    } else if (node.type === "http") {
                        subflowOutputType = node.type;
                    } else {
                        subflowOutputType = subflowOutputContext?.keys[0];
                        if (subflowOutputType === "network_interface") {
                            subflowOutputType = "interface";
                        } else if (subflowOutputType === "network_device" || subflowOutputType === "cpu_util_type" || subflowOutputType === "memory_type" || subflowOutputType === "disk_path") {
                            subflowOutputType = "device";
                        }
                        if (subflowOutputType) {
                            subflowOutputType = `${startCase(toLower(subflowOutputType.replace(/_/g, ' ')))}`;
                        }
                    }
                    break loopSubflowOutputContextReversed;
                }
            }
        }
    }
    if (!subflowOutputType) {
        subflowOutputType = context?.triggerContext?.keys[0];
        getSubflowOutputFormatFromSubflowInput = true;
        if (subflowOutputType === "network_interface") {
            subflowOutputType = "interface";
            subflowOutputType = `${startCase(toLower(subflowOutputType.replaceAll('_', ' ')))}`;
        } else if (subflowOutputType === "network_device" || subflowOutputType === "cpu_util_type" || subflowOutputType === "memory_type" || subflowOutputType === "disk_path") {
            subflowOutputType = "device";
            subflowOutputType = `${startCase(toLower(subflowOutputType.replaceAll('_', ' ')))}`;
        } else if (subflowOutputType === "json_input" || subflowOutputType === "json") {
            subflowOutputType = "JSON";
            subflowOutputType = `${startCase(toLower(subflowOutputType.replaceAll('_', ' ')))}`;
        }
    }
    if (subflowOutputType) {
        if (subflowOutputContextLast?.isTimeseries || (getSubflowOutputFormatFromSubflowInput && context?.triggerContext?.isTimeseries)) {
            subflowOutputFormat = "time series";
        } else if ((subflowOutputContextLast && 'isTimeseries' in subflowOutputContextLast) || (context?.triggerContext && 'isTimeseries' in context.triggerContext)) {
            subflowOutputFormat = "summary";
        }
    }
    return { subflowOutputType, subflowOutputFormat };
}

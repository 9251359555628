/** This file defines the test dialog React component. The test 
 *  dialog allows you to call a service to test the AI node settings for the user.
 *  @module */
import React, { useState, useEffect } from "react";
import { Button, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core";
import { 
    GenAiRequestContentType, GenAiRequest, GenAiRequestRole, GenAiService, GenAiResponse, 
    GenAiResponseContentType 
} from "utils/services/GenAiApiService";
import { ErrorToaster, useStateSafePromise } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { STRINGS } from "app-strings";
import { getErrorsFromApiResponse } from "utils/errors/ErrorUtils";

/** this interface defines the properties that are used to initialize the test dialog. */
interface TestDialogProperties {
    /** a string with the user provided instructions. */
    instructions: string;
    /** a string with the user provided query. */
    query: string;
    /** a boolean value, true if the dialog should be opened. */
    open: boolean;
    /** the handler for dialog close events. */
    onClose: () => void
}

/** Component for displaying a dialog to test the ai node
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the test dialog React component. */
export function TestDialog(props: TestDialogProperties): JSX.Element {
    const [request, setRequest] = useState<GenAiRequest>();
    const [queryText, setQueryText] = useState<string>(props.query);
    const [loading, setLoading] = useState<boolean>(false);
    const [resultText, setResultText] = useState<string>("");
    const [executeSafely] = useStateSafePromise();

    useEffect(() => {
        setQueryText(props.query);
    }, [props.query]);

    useEffect(
        () => {
            if (request) {
                setLoading(true);
                const genAiPromise = executeSafely(GenAiService.runAiRequest(request));
                genAiPromise.then(
                    (result: GenAiResponse) => {
                        if (
                            result?.messages?.length && result.messages[0].content?.length && 
                            result.messages[0].content[0].type === GenAiResponseContentType.text
                        ) {
                            setResultText(result.messages[0].content[0].text);
                        }
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        ErrorToaster({ 
                            message: getErrorsFromApiResponse(
                                error, STRINGS.runbookEditor.nodeLibrary.nodes.genai.errors, 
                                STRINGS.runbookEditor.nodeLibrary.nodes.genai.genericTestError
                            ) 
                        });
                    }
                );    
            }
        },
        [request, executeSafely]
    )

    return <Dialog title={STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.title} 
        isOpen={props.open} usePortal={true} className={"test-query-dialog"}
        onClose={props.onClose}
        //portalClassName={props.portalClassName}
        canOutsideClickClose={true}
        canEscapeKeyClose={false}
        isCloseButtonShown={true}
    >
        <div className={Classes.DIALOG_BODY}>
        <label className='pb-1'>{STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.queryLabel}</label>
            <TextArea
                required={ true }
                defaultValue={props.query}
                name={ "test-dialog-query-content" }
                growVertically={ true }
                fill={ true }
                placeholder={STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.queryPlaceholder}
                style={{minHeight: "120px", maxHeight: "300px", overflowY: "auto", resize: "both"}}
                onChange={ e => {
                    const query = e.target.value;
                    setQueryText(query);
                } }
            />
            <div className="d-flex justify-content-end w-100 mt-2">
                <Button active={true} outlined={true} disabled={!Boolean(queryText?.length && queryText.length > 0 && !loading)}
                    text={STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.testButton}
                    type="button"
                    aria-label="test"
                    name={ "test-dialog-test-button" }
                    intent={Intent.PRIMARY}
                    onClick={async (evt) => {
                        // Create a request object
                        const request: GenAiRequest = createRequest(props.instructions, queryText);
                        setRequest(request);
                    }}
                />
            </div>
            <br />
            <label className='pt-4 pb-1'>{STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.responseLabel}</label>
            <DataLoadFacade loading={loading} fullScreen={false} data={true} error={false} 
                showContentsWhenLoading={true} transparent={true} className="test-conent-load-facade"
            >
                <TextArea
                    required={ true }
                    value={resultText}
                    name={ "response-content" }
                    growVertically={ true }
                    fill={ true }
                    style={{minHeight: "120px", maxHeight: "500px"}}
                    onBlur={ e => {
                        //setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: e.target.value });
                    } }
                />
            </DataLoadFacade>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button active={true} outlined={true} aria-label="cancel" type="button"
                    name={ "test-dialog-close-button" }
                    onClick={(evt) => {
                        props.onClose();
                    }}
                    text={STRINGS.runbookEditor.nodeLibrary.nodes.genai.testDialog.closeButton}
                />
            </div>
        </div>
    </Dialog>;
}

/** generates a request object from instructions and query text.
 *  @param instructions a String with the instructions.
 *  @param query a String with the query.
 *  @returns a GenAiRequest object with the request. */
export function createRequest(instructions: string, query: string): GenAiRequest {
    const request: GenAiRequest = {
        "messages": [
            {
                "role": GenAiRequestRole.system,
                "content": [
                    {
                        "type": GenAiRequestContentType.text,
                        "text": instructions || ""
                    }
                ]
            }, {
                "role": GenAiRequestRole.user,
                "content": [
                    {
                        "type": GenAiRequestContentType.text,
                        "text": query || ""
                    }
                ]
            }
        ]
    };
    return request;
}

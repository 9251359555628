/** This module contains the component for the navigator page.  The navigator page displays
 *      and edits the NPM Plus data viewer.
 *  @module
 */

import React, { useRef, useState, useEffect } from "react";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import NavigatorToolbar, { ToolbarAction } from "./views/toolbar/NavigatorToolbar";
import Layout from "components/common/vis-framework/layout/Layout";
import { LAYOUT_TYPE, LayoutConfig } from "components/common/vis-framework/layout/Layout.type";
import { INTERACTION_TYPE, InteractionConfig, WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type";
import { DashboardConfig } from "pages/dashboards/Dashboard.type";
import NavigatorWidget, { NavigatorWidgetRef, WidgetEditAction, filterOperator } from "./views/widget/NavigatorWidget";
import { FILTER_KEYS, FILTER_TYPE, FilterEntry, NavigatorConfig, NavigatorWidgetConfig, QueryConfig } from "./Navigator.type";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AnchorButton, Button, InputGroup, TextArea, Intent } from "@blueprintjs/core";
import { useQueryParams } from "utils/hooks";
import { IS_DASHBOARD, PARAM_NAME } from "components/enums/QueryParams";
import { getFakeDashboard } from "pages/dashboard-list/views/dashboard-list/DashboardList";
import AutoUpdateControl, { AutoUpdateState, TimeType } from "components/common/auto-update/AutoUpdateControl";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer";
import { DragData, EntityList } from "components/common/vis-framework/components/EntityList";
import { WidgetEditor } from "components/common/vis-framework/components/widget-editor/WidgetEditor";
import { DetailsPanel } from "components/reporting/containers/details-panel/DetailsPanel";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { SIZE } from "components/enums";
import { IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import AddNewWidgetControl from "./views/controls/AddNewWidgetControl";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import "./NavigatorPage.scss";

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60* Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

export let USE_DAL: boolean = false;
export let STARTING_OBJ_TYPE: string = "npm_plus.traffic"; //"app_response_and_profiler"; //"profiler_traffic"; //"network_host.traffic"; // "tcp_connections"
export function updateConstantsForTesting(useDal, objType): void {
    USE_DAL = useDal;
    STARTING_OBJ_TYPE = objType;
}

/** Renders the navigator page.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator page component.*/
const NavigatorPage = (props): JSX.Element => {
    const { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm, PARAM_NAME.devel] });
    //const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const [loadNavigator, setLoadNavigator] = useState<boolean>(true);

    const loading = useRef<boolean>(false);

    const [navigatorName, setNavigatorName] = useState<string>("New Navigator");
    const [navigatorDesc, setNavigatorDesc] = useState<string>("");

    const [layout, setLayout] = useState<LayoutConfig>({type: LAYOUT_TYPE.VERTICAL});

    const [edit, setEdit] = useState<boolean>(IS_DASHBOARD);

    // Let's initialize the DO metadata for all the components within the Navigator so they can use it freely
    const [doUtilsInitialized, setDOUtilsInitialized] = useState(false);
    const [executeSafely] = useStateSafePromise();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(() => {
            setDOUtilsInitialized(true);
        });
    }, [setDOUtilsInitialized, executeSafely]);

    let firstGroupBy: string[] = [];
    if (DataOceanUtils.dataOceanMetaData.obj_types?.[STARTING_OBJ_TYPE]?.group_by_required) {
        const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
            DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[STARTING_OBJ_TYPE].keys || []
        );
        firstGroupBy = expandedKeys.length > 0 ? [expandedKeys[0].id] : [];
    } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[STARTING_OBJ_TYPE]?.group_by?.length) {
        firstGroupBy = [DataOceanUtils.dataOceanMetaData.obj_types![STARTING_OBJ_TYPE].group_by![0]];
    }

    const [widgets, setWidgets] = useState<NavigatorWidgetConfig[]>([createNewWidget(WIDGET_TYPE.TABLE, [], STARTING_OBJ_TYPE, firstGroupBy)]);

    const [showEntityList, setShowEntityList] = useState<boolean>(true);

    const [editWidgetId, setEditWidgetId] = useState<string | undefined>(undefined);
    const [showNewBlade, setShowNewBlade] = useState<boolean>(false);
    const [showNewBladeFilters, setShowNewBladeFilters] = useState<FilterEntry[]>([]);
    const [showNewOrEditBladeObjType, setShowNewOrEditBladeObjType] = useState<string>(STARTING_OBJ_TYPE);

    const widgetRefs = useRef<Array<NavigatorWidgetRef>>([]);

    const [dialogState, setDialogState] = useState<any>({showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null});

    useEffect(
        () => {
            /* istanbul ignore next */
            async function retrieveNavigator() {
                try {
                    let navigator: DashboardConfig | NavigatorConfig | undefined = getFakeDashboard(params[PARAM_NAME.rbConfigId]); // await runbookService.getRunbook(currentRunbook.current.id);
                    if (navigator) {
                        /*************************************************************************************/
                        //Do a conversion from one format to another right Here
                        /*********************************************************************************** */
                        //resetHistory();
                        setEdit(false);
                        setNavigatorName(navigator.name);
                        setNavigatorDesc(navigator.description || "");
                        if (navigator?.widgets?.length) {
                            setWidgets(navigator.widgets as NavigatorWidgetConfig[]);
                        }
                        if (navigator?.layout) {
                            setLayout(((navigator as any) as NavigatorConfig).layout);
                        }
                        if (navigator.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = navigator.endTime;
                            newAutoUpdate.enabled = false;
                            setAutoUpdate(newAutoUpdate);
                        }
                    }
                    //setLoading(false);
                } catch (error) {
                    console.error(error);
                    //setLoading(false);
                }
            }
            /* istanbul ignore if */
            if (loadNavigator) {
                setLoadNavigator(false);
                if (params[PARAM_NAME.rbConfigId]) {
                    retrieveNavigator();
                } else {
                    console.log("need to handle this");
                }
                //setRunbookModified(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadNavigator]
    );

    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    const handleColumnsChange = (columns: Array<string>) => {
        if (editWidgetId) {
            for (const widget of widgets) {
                if (editWidgetId === widget.id) {
                    // We need to update the query as well
                    if (widget.queryConfig?.properties) {
                        const metrics = [...columns];
                        if (metrics.includes("group_column")) {
                            metrics.splice(metrics.indexOf("group_column"), 1);
                        }
                        widget.queryConfig.properties.metrics = metrics;
                    }
                    if (widget.options) {
                        widget.options.columns = columns;
                    }
                    setWidgets([...widgets]);
                    break;
                }
            }    
        }
    };

    const handleEditBladeClose = () => {
        setEditWidgetId(undefined);
        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
    };

    const handleNewBladeClose = () => {
        setShowNewBlade(false);
        setShowNewBladeFilters([]);
        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
    };

    const notifyToolbarAction = (action: string, value: any): void => {
        switch (action) {
            case ToolbarAction.ADD_WIDGET:
                const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                if (value?.dropIndex) {
                    newWidgets.splice(value.dropIndex, 0, createNewWidget(WIDGET_TYPE.TABLE, widgets, value.objType, value.groupBy ? [value.groupBy] : undefined));
                } else if (value?.droppedOnWidgetId && value?.groupBy) {
                    const droppedOnIndex = newWidgets.findIndex(widget => widget.id === value.droppedOnWidgetId);
                    // Create a new object so its reference is not the same, this will cause a new query to go out.
                    newWidgets[droppedOnIndex].queryConfig = {...newWidgets[droppedOnIndex].queryConfig} as QueryConfig;
                    newWidgets[droppedOnIndex].queryConfig!.properties.groupBy = [value.groupBy];
                    newWidgets[droppedOnIndex].queryConfig!.properties.objType = value.objType;
                } else {
                    newWidgets.push(createNewWidget(WIDGET_TYPE.TABLE, widgets, value?.objType || STARTING_OBJ_TYPE, value?.groupBy ? [value.groupBy] : undefined));
                }
                setWidgets(newWidgets);
                break;
            case ToolbarAction.SET_LAYOUT:
                setLayout({type: value});
                break;
            case ToolbarAction.EDIT:
                setEdit(!edit);
/*
                for (const widgetRef of widgetRefs.current) {
                    widgetRef.editWidget(edit);
                }
*/
                break;
            case ToolbarAction.IMPORT:
                widgetRefs.current = [];
                importNavigator(setDialogState, setWidgets, setLayout, setNavigatorName, setNavigatorDesc, setAutoUpdate, autoUpdate);
                break;
            case ToolbarAction.EXPORT:
                const navigatorState: NavigatorConfig = {
                    id: getUuidV4(),
                    name: navigatorName || "My Navigator",
                    description: navigatorDesc || "this is my new navigator",
                    layout, widgets
                };
                if (autoUpdate.time) {
                    navigatorState.endTime = autoUpdate.time;
                }
                exportNavigator(navigatorState, setDialogState);
                break;
            case ToolbarAction.SAVE:
                saveNavigator(dialogState, setDialogState);
                break;
        }
    };

    const onDrop = (event: any) => {
        event.preventDefault();
        const stringifiedQueryInfo: string = event.dataTransfer.getData("queryInfo");
        if (stringifiedQueryInfo) {
            const queryInfo: DragData = JSON.parse(stringifiedQueryInfo);
            //alert("queryInfo: " + JSON.stringify(queryInfo));
            const objType: string = queryInfo.objType;
            const dataSource: string = queryInfo.dataSource;
            const groupBy: string = queryInfo.groupBy;
            const droppedOnExistingWidget = event.target.closest('.widget-item');
            const dropIndex = Array.from(event.target.children).findIndex(
                // for a vertical layout find the index of the dropped child element
                (child: any) => child.getBoundingClientRect().bottom > event.clientY
            );
            if (droppedOnExistingWidget) {
                notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: droppedOnExistingWidget.id, dropIndex: undefined});
            } else if (dropIndex !== -1) {
                // dropping between widgets
                notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: "", dropIndex});
            } else {
                notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: undefined, dropIndex: undefined});
            }    
        }
    };

    return <PageWithHeader
        name="Navigator"
        title={
            //<div>{navigatorName + " (Beta - for demos only)"}</div>
            <div className="ml-2 font-weight-bold w-100 text-center d-none d-sm-block">
                {navigatorName + " (Alpha)"}
                <span style={{ verticalAlign: "text-bottom" }}>
                <WrapInTooltip tooltip="edit">
                    <AnchorButton minimal icon={IconNames.EDIT} onClick={() => {
                        showNavigatorSettings(navigatorName, navigatorDesc, setNavigatorName, setNavigatorDesc, dialogState, setDialogState);
                    }}/>
                </WrapInTooltip>
                </span>
            </div>
        }
        icon={SDWAN_ICONS.INCIDENT} showTimeBar={false}
        rightAlignedControls={<AutoUpdateControl autoUpdate={autoUpdate} timeType={TimeType.DURATION}
            showPlayPauseButton={true} onAutoUpdateChanged={(autoUpdate) => {
                setAutoUpdate(autoUpdate);
            }}
            showRefreshButton={!autoUpdate.enabled} showTimeControl
            className="d-none d-md-block"
        />}
    >
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))}/>   
        <AutoUpdateContext.Provider value={autoUpdate}>
        {IS_DASHBOARD && <NavigatorToolbar
            edit={edit}
            notifyToolbarAction={notifyToolbarAction}
        />}
        <DataLoadFacade loading={loading.current || !doUtilsInitialized} error={undefined /*data.error*/} data={undefined /*data.data*/} showContentsWhenLoading={true} className="npm-plus-column-container">
            <TwoColumnContainer hideLeftColumn={IS_DASHBOARD || !showEntityList} firstColumnSize={SIZE.s} noPaddingOnFirstColumn={true} noPaddingOnSecondColumn={true} doNotSetColumnWidth={false}>
                {doUtilsInitialized && <EntityList objType={STARTING_OBJ_TYPE} />}
                {doUtilsInitialized && <div className={showEntityList ? "widget-area pb-40 h-100" : "widget-area pb-40 entity-list-hidden h-100"}
                    onDrop={onDrop}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div>
                        <WrapInTooltip tooltip={"Show/hide filter sidebar"}>
                            <Button icon={showEntityList ? IconNames.DOUBLE_CHEVRON_LEFT : IconNames.DOUBLE_CHEVRON_RIGHT}
                                className={"filter-toggle-button mr-3"} intent={Intent.NONE}
                                onClick={() => {
                                    setShowEntityList(!showEntityList);
                                }}
                            />
                        </WrapInTooltip>
                    </div>
                    <Layout layout={layout}
                        onLayoutChanged={(layout: any) => {
                            setLayout(layout);
                        }}
                    >
                        {widgets.map(widgetConfig => {
                            return <NavigatorWidget
                                key={widgetConfig.id}
                                config={widgetConfig}
                                widgets={widgets}
                                layout={layout.type}
                                isEditing={edit}
                                notifyWidgetEditAction={(type: WidgetEditAction, value?: any) => {
                                    switch (type) {
                                        case WidgetEditAction.COLUMNS_CHANGE: {
                                            setEditWidgetId(widgetConfig.id);
                                            setShowNewOrEditBladeObjType(widgetConfig.queryConfig?.properties.objType || STARTING_OBJ_TYPE);
                                            break;
                                        }
                                        case WidgetEditAction.CONFIG_CHANGE: {
                                            const config: NavigatorWidgetConfig = value as NavigatorWidgetConfig;
                                            const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                                            for (let index = 0; index < newWidgets.length; index++) {
                                                if (newWidgets[index].id === config.id) {
                                                    newWidgets[index] = config;
                                                    break;
                                                }
                                            }
                                            setWidgets(newWidgets);
                                            break;
                                        }
                                        case WidgetEditAction.DELETE: {
                                            const remainingWidgets: NavigatorWidgetConfig[] = [];
                                            for (let index = 0; index < widgets.length; index++) {
                                                if (widgets[index].id !== widgetConfig.id) {
                                                    remainingWidgets.push(widgets[index]);
                                                }
                                            }
                                            setWidgets(remainingWidgets);
                                            break;
                                        }
                                    }
                                }}
                                notifyInteraction={(type: INTERACTION_TYPE,  data: any[]) => {
                                    //alert(JSON.stringify(entities));
                                    if (type === INTERACTION_TYPE.GROUP) {
                                        const groupInteraction: InteractionConfig | undefined = (widgetConfig.interactions || []).find(interactionConfig => interactionConfig.type === INTERACTION_TYPE.GROUP);
                                        if (groupInteraction) {
                                            widgetRefs.current.forEach((widget) => {
                                                if (groupInteraction.widgetIds.includes(widget.getId())) {
                                                    widget.notifyNewRunbookInput(data);
                                                }
                                            });
                                        } else if (!IS_DASHBOARD) {
                                            // Use the interactions to get the filters for the add new bar
                                            // We should do this in a better way, but this should work for now.
                                            const filters: FilterEntry[] = [];
                                            if (data?.length) {
                                                for (const filterKey in data[0]) {
                                                    if (FILTER_KEYS.includes(filterKey)) {
                                                        filters.push({type: filterKey as FILTER_TYPE, values: [data[0][filterKey]]});
                                                    }
                                                }
                                            }
                                            setShowNewBlade(true);
                                            setShowNewBladeFilters(filters);
                                            setShowNewOrEditBladeObjType(widgetConfig.queryConfig?.properties?.objType || STARTING_OBJ_TYPE);
                                        }
                                    }
                                }}
                                notifyFiltersetChange={(filterSet: any[]) => {
                                    const filters: FilterEntry[] = [];
                                    const newFiltered = filterSet.map(xp => {
                                        return {
                                            type: FILTER_TYPE[xp.category],
                                            values: [xp.value],
                                            operator: filterOperator[xp.operator]
                                        }
                                    });
                                    filters.push(...newFiltered);
                                    for (const widget of widgets) {
                                        if (widgetConfig.id === widget.id) {
                                            // We need to update the query as well
                                            if (widget.queryConfig?.properties) {
                                                widget.queryConfig.properties.filters = filters;
                                            }
                                            setWidgets([...widgets]);
                                            break;
                                        }
                                    }                            
                                }}
                                ref={(element: NavigatorWidgetRef) => {
                                    const ids = widgetRefs.current.map(widget => widget.getId());
                                    if (!ids.includes(element.getId())) {
                                        widgetRefs.current.push(element);
                                    } else {
                                        const widgetId = element.getId();
                                        for (let widgetIndex = 0; widgetIndex < widgetRefs.current.length; widgetIndex++) {
                                            if (widgetRefs.current[widgetIndex].getId() === widgetId) {
                                                widgetRefs.current[widgetIndex] = element;
                                                break;
                                            }
                                        }
                                    }
                                }}
                            ></NavigatorWidget>;
                        })}
                    </Layout>
                </div>}
            </TwoColumnContainer>
            {editWidgetId && <DetailsPanel size={SIZE.s} anchorElement={undefined}
                resizableElement={undefined}
            >
                <BladeContainer className="edit-widget-blade" showIconWithoutBg={true}
                    title={
                        <div className={"text-nowrap text-truncate ml-2"}>{STRINGS.navigators.widgetEditor.title}</div>
                    }
                    onCloseClicked={() => {
                        setEditWidgetId(undefined);
                    }}
                    noContentPadding
                >
                    <div className="p-2">
                        <WidgetEditor 
                            columnsChange={handleColumnsChange} bladeClose={handleEditBladeClose} 
                            selectedColumns={widgets.find((widget) => widget.id === editWidgetId)?.options?.columns as string[] || []}
                            displayColumns={["group_column", ...DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].metrics]} 
                        />
                    </div>
                </BladeContainer>
            </DetailsPanel>}
            {showNewBlade && <DetailsPanel size={SIZE.s} anchorElement={undefined}
                resizableElement={undefined}
            >
                <BladeContainer className="new-widget-blade" icon={IconNames.ADD_ROW_BOTTOM} showIconWithoutBg={true}
                    title={<>
                        <div className={"text-nowrap text-truncate"}>{STRINGS.navigator.addNewWidgetControl.title}</div>
                        <div className="display-8">{STRINGS.navigator.addNewWidgetControl.subTitle}</div>
                    </>}
                    onCloseClicked={() => {
                        setShowNewBlade(false);
                        setShowNewBladeFilters([]);
                        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
                    }}
                    noContentPadding
                >
                    <div className="p-2">
                        <AddNewWidgetControl
                            objType={showNewOrEditBladeObjType}
                            displayColumns={["group_column", ...DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].metrics]} 
                            filters={showNewBladeFilters}
                            onAddNewWidget= {(widgetType: WIDGET_TYPE, objType: string, selectedColumns: string[], selectedGroupBys: string[], filters: FilterEntry[]) => {
                                setShowNewBlade(false);
                                const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                                newWidgets.push(createNewWidget(widgetType, widgets, objType, selectedGroupBys, selectedColumns, filters));
                                setWidgets(newWidgets);        
                            }}
                            bladeClose={handleNewBladeClose} 
                        />
                    </div>
                </BladeContainer>
            </DetailsPanel>}
        </DataLoadFacade>
        </AutoUpdateContext.Provider>
    </PageWithHeader>;
};

export default NavigatorPage;

/** creates the specification for a new widget, including the query settings.
 *  @param widgetType the WIDGET_TYPE with the type of widget that is to be created.
 *  @param widgets the current set of widget configuration.  This is only used to make sure the name of the 
 *      new widget is unique.
 *  @param objType a string with the object type.
 *  @param groupBy the group bys to use if any.
 *  @param columns the array of string column ids, if any.
 *  @param filter the array of FilterEntry objects, if any.
 *  @returns a NavigatorWidgetConfig object with the widget configuration and query configuration. */
function createNewWidget(
    widgetType: WIDGET_TYPE, widgets: NavigatorWidgetConfig[], objType: string, groupBys?: string[],
    columns?: string[], filters?: FilterEntry[]
): NavigatorWidgetConfig {
    let maxWidgetIndex = 0;
    for (const widget of widgets) {
        if (widget.name.startsWith("New Widget ")) {
            try {
                const widgetIndex: number = parseInt(widget.name.substring("New Widget ".length, widget.name.length));
                if (!Number.isNaN(widgetIndex)) {
                    maxWidgetIndex = Math.max(maxWidgetIndex, widgetIndex);
                }
            } catch (error) {}
        }
    }

    // Choose some default metrics
    const defaultMetrics = DataOceanUtils.dataOceanMetaData.obj_types[objType].metrics;
    const firstDefaultMetric = defaultMetrics?.length ? defaultMetrics[0] : undefined;
    const secondDefaultMetric = defaultMetrics?.length > 1 ? defaultMetrics[1] : undefined;
    const thirdDefaultMetric = defaultMetrics?.length > 2 ? defaultMetrics[2] : undefined;

    let widgetOptions = {};
    const displayColumns = [...(columns || ["group_column", firstDefaultMetric || "", secondDefaultMetric || "", thirdDefaultMetric || ""])];
    let metrics = [...displayColumns];
    if (metrics.includes("group_column")) {
        metrics.splice(metrics.indexOf("group_column"), 1);
    }
    let limit = 5;
    switch (widgetType) {
        case WIDGET_TYPE.TABLE:
            widgetOptions = {
                sortColumn: "",
                sortOrder: "asc",
                columns: displayColumns
            };
            break;
        case WIDGET_TYPE.PIE:
            widgetOptions = {
                "metric": metrics?.length > 0 ? metrics[0] : firstDefaultMetric,
                "style": "donut",
                "showPercentage": false,
                "showValue": false,
                "showLegend": false,
                "legendPosition": "top"
            };
            metrics = [metrics?.length > 0 ? metrics[0] : firstDefaultMetric || ""];
            break;
        case WIDGET_TYPE.TIMESERIES:
            limit = 1;
            widgetOptions = {
                "showLegend": false,
                "legendPosition": "top",
                "metrics": [metrics?.length > 0 ? metrics[0] : firstDefaultMetric]
            };
            metrics = [metrics?.length > 0 ? metrics[0] : firstDefaultMetric || ""];
            break;
        case WIDGET_TYPE.CORRELATION:
            limit = 1;
            widgetOptions = {
                "showLegend": false,
                "legendPosition": "top",
                "xMetric": metrics?.length > 0 ? metrics[0] : firstDefaultMetric,
                "yMetric": metrics?.length > 1 ? metrics[1] : secondDefaultMetric
            };
            metrics = [
                metrics?.length > 0 ? metrics[0] : firstDefaultMetric || "", 
                metrics?.length > 1 ? metrics[1] : secondDefaultMetric || ""
            ];
            break;
    }
    const timeSeries = widgetType === WIDGET_TYPE.TIMESERIES || widgetType === WIDGET_TYPE.CORRELATION;
    return {
        id: getUuidV4(), name: "New Widget " + (maxWidgetIndex + 1), widgetType: widgetType, options: widgetOptions as any,
        queryConfig: {properties: {
            objType: objType,
            timeSeries: timeSeries,
            topBy: [{id: firstDefaultMetric || "", direction: "desc"}],
            limit: limit,
            duration: 900,
            filters: filters || [],
            metrics: metrics,
            groupBy: groupBys ? groupBys : []
        }}                    
    };
}

/** Creates a popup that imports a template into the template viewer.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param setWidgets sets the array of WidgetConfig objects.
 *  @param setLayout sets the template layout.
 *  @param setNavigatorName function to set the template name.
 *  @param setNavigatorDescr function to set the template description.
 *  @param setAutoUpdate sets the autoupate state.
 *  @param autoUpdate the current AutoUpdate state. */
function importNavigator(
    setDialogState: (dialogState: DialogState) => void,
    setWidgets: (widgetConfigs: NavigatorWidgetConfig[]) => void,
    setLayout: (layout: LayoutConfig) => void,
    setNavigatorName: (name: string) => void,
    setNavigatorDescr: (name: string) => void,
    setAutoUpdate: (autoUpdate: AutoUpdateState) => void,
    autoUpdate: AutoUpdateState
): void {
    const newDialogState: any = {showDialog: true, title: STRINGS.navigator.importDialog.title};
    newDialogState.dialogContent = <>
        <div className="mb-3"><span>{STRINGS.navigator.importDialog.preText}</span></div>
        <input id="runbook-file" type="file" />
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        const fileList = (document.getElementById("runbook-file") as any).files;
        if (fileList && fileList.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;
                if (text && text.length > 0) {
                    let errors: Array<string> = [];
                    try {
                        setDialogState(updateDialogState(newDialogState, true, true, []));
                        const importedNavigator: NavigatorConfig = JSON.parse(text);
                        const returnedNavigator = await saveImportedNavigator(importedNavigator);
                        if (returnedNavigator?.name) {
                            setNavigatorName(returnedNavigator.name);
                        }
                        setNavigatorDescr(returnedNavigator.description || "");
                        if (returnedNavigator?.widgets?.length) {
                            setWidgets(returnedNavigator.widgets as NavigatorWidgetConfig[]);
                        }
                        if (returnedNavigator?.layout) {
                            setLayout(returnedNavigator.layout);
                        }
                        if (returnedNavigator?.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = returnedNavigator.endTime;
                            newAutoUpdate.enabled = false;
                            setAutoUpdate(newAutoUpdate);
                        }
                    } catch (error) {
                        const errorMsg = (error as any)?.response?.status === 422 && (error as any)?.response?.data?.details[0]?.code
                            ? STRINGS.runbookEditor.errors.codes[(error as any)?.response?.data?.details[0]?.code]
                            : STRINGS.formatString(STRINGS.runbooks.importDialog.errorText, {variant: "dashboard"}) + '<br/>' + error;
                        errors.push(errorMsg);
                    }
                    setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
                }
            };
            fileReader.readAsText(fileList[0]);
        }
    }} text={STRINGS.runbooks.importDialog.btnText} />;
    setDialogState(newDialogState);
}

/** saves a new navigator from the contents of the import file.
 *  @param navigator the JSON object with the contents of the import file.
 *  @returns a Promise which resolves to the navigator that was saved. */
async function saveImportedNavigator(navigator: NavigatorConfig): Promise<NavigatorConfig> {
    // This is just dummy code we need to figure out what to do with a save and get a back-end service to do it
    return Promise.resolve(navigator);
}

/** Creates a popup that saves the navigator to Azure.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
/* istanbul ignore next */
async function saveNavigator(
    newDialogState: DialogState, setDialogState: (dialogState: DialogState) => void
): Promise<any> {
    const origName = "My Navigator", origInfo = "My Description";
    newDialogState.title = STRINGS.navigator.saveDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.navigator.saveDialog.text}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.navigator.saveDialog.nameLabel}</label></td>
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text"
                        defaultValue={origName} style={{width: "300px"}}
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.navigator.saveDialog.descLabel}</label></td>
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={origInfo}
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    }
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;
    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.navigator.saveDialog.saveBtnText}
                onClick={async (evt) => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    }
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    setDialogState(updateDialogState(newDialogState, true, true, []));
}

/** exports the currently displayed navigator.
 *  @param navigator the object with the navigator configuration.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function exportNavigator(navigator: NavigatorConfig, setDialogState: (dialogState: DialogState) => void): void {
    const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.navigator.exportDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.navigator.exportDialog.preText}</span></div>;
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);

    try {
        const newDialogState: any = {showDialog: true, title: STRINGS.navigator.exportDialog.title};
        newDialogState.dialogContent = <>
            <div className="mb-3"><span>{STRINGS.navigator.exportDialog.preText}</span></div>
            <textarea defaultValue={JSON.stringify(navigator, null, 4)} style={{width: "470px", height: "350px"}} disabled={true} />
        </>;
        newDialogState.dialogFooter = <CopyToClipboard text={JSON.stringify(navigator)}>
            <Button active={true} outlined={true}
                text={STRINGS.navigator.exportDialog.btnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>;
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(newDialogState, true, false, [STRINGS.navigator.exportDialog.errorText]);
        setDialogState(afterDialogState);
    }
}

/** Creates a popup that shows the navigator settings.
 *  @param name the current navigator name.
 *  @param decription the current navigator description.
 *  @param setNavigatorName function to set the navigator name.
 *  @param setNavigatorDescr function to set the navigator description.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
async function showNavigatorSettings(
    name: string, description: string,
    setNavigatorName: (name: string) => void,
    setNavigatorDescr: (name: string) => void,
    dialogState: DialogState, setDialogState: (dialogState: DialogState) => void
): Promise<any> {
    const newDialogState = updateDialogState(dialogState, true, false, [], []);

    newDialogState.title = STRINGS.navigators.settingsDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.navigators.settingsDialog.settingsText}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncNameLabel}</label></td>
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text"
                        defaultValue={name} style={{width: "300px"}}
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncDescLabel}</label></td>
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={description}
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    }
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;

    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.navigators.settingsDialog.saveBtnText}
                onClick={async (evt) => {
                    const name = (document.getElementById("runbook-flow-name") as HTMLInputElement).value;
                    const desc = (document.getElementById("runbook-flow-desc") as HTMLInputElement).value;
                    setNavigatorName(name);
                    setNavigatorDescr(desc);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    }
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    newDialogState.loading = false;
    setDialogState(newDialogState);
}

import React, { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { Tab, Tabs } from "@blueprintjs/core";
import { Icon, IconName, IconNames } from "@tir-ui/react-components";
import { AWS_TYPES_TO_LABEL_MAP, AWSSupportedTypes, CloudIMDataInfo, CloudIMSupportedTypes, NETIM_TYPES_TO_LABEL_MAP, NetIMSupportedDeviceTypes, NetIMSupportedLinkTypes } from "utils/cloudim/TopologyUtils";
import { PROVIDER_TYPES, TIME_FORMAT } from "components/enums";
import { STRINGS } from "app-strings";
import { getElapsedTimeInfo } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { getFilteredPropertiesData } from "utils/cloudim/TopologyUtils";
import AttributesTab from "components/common/graph/cloudim-react-flow/components/properties/tabs/attributes/AttributesTab";
import MetricsTab from "components/common/graph/cloudim-react-flow/components/properties//tabs/metrics/MetricsTab";

import './CloudIMPropertiesContainer.scss';

/* To Use CloudIMPropertiesContainer
Without Query (AWS/NetIM):
    <CloudIMPropertiesContainer
        icon={icon}
        filter={{
            provider: PROVIDER_TYPES.AWS / PROVIDER_TYPES.ONPREM,
            type: data.type,
            id: data.label,
        }}
        nodeData={data}
    />
With Query (AWS):
    <CloudIMPropertiesContainer
        icon={icon}
        filter={{
            provider: PROVIDER_TYPES.AWS,
            type: data.type,
            id: data.label,
            region: data?.attributes?.Region,
            tokens: [data.label, data.type],
        }}
        useQueryData={true}
    />
With Query (NetIM):
    <CloudIMPropertiesContainer
        icon={icon}
        filter={{
            provider: PROVIDER_TYPES.ONPREM,
            type: data.type,
            id: data.label,
            latitude: data?.attributes?.geo?.latitude,
            longitude: data?.attributes?.geo?.longitude,
            tokens: [data.label],
        }},
        useQueryData={true}
    />
*/

export interface PropertiesFilters {
    provider: PROVIDER_TYPES;
    type: AWSSupportedTypes | NetIMSupportedDeviceTypes | NetIMSupportedLinkTypes | CloudIMSupportedTypes;
    id: string;
    tokens?: string[];
    region?: string;
    latitude?: number;
    longitude?: number;
}

export interface PropertiesProps {
    className?: string;
    icon: string | IconName;
    filter: PropertiesFilters;
    useQueryData?: boolean;
    loading?: boolean;
    nodeData?: CloudIMDataInfo;
}

const CloudIMPropertiesContainer = ({
    className,
    icon,
    filter,
    useQueryData = false,
    loading = false,
    nodeData
}: PropertiesProps) => {
    const [data, setData] = useState<CloudIMDataInfo | undefined>(nodeData);
    const [isLoading, setIsLoading] = useState<boolean>(loading);

    // TODO: Modify this query for a more precise one once it is available
    const awsQuery = useQuery({
        query: new Query(loader("./graphql/aws-properties.graphql")),
        name: "Filtered AWS Resource",
        requiredFilters: [FILTER_NAME.regions],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.regions]: filter.region,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        queryVariables: {
            unique: true
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    // TODO: Modify this query for a more precise one once it is available
    const netImQuery = useQuery({
        query: new Query(loader("./graphql/netim-properties.graphql")),
        name: "Filtered NetIM Resource",
        requiredFilters: [FILTER_NAME.latitude, FILTER_NAME.longitude],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.latitude]: filter.latitude,
            [FILTER_NAME.longitude]: filter.longitude,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    let typeLabel: string = "";
    let metrics: boolean = false;
    let headerSublabel: string = "";

    useEffect(
        () => {
            if (useQueryData) {
                setIsLoading(true);
                if (filter.provider === PROVIDER_TYPES.AWS) {
                    awsQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.regions]: filter.region, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData.resources, filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
                    netImQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.latitude]: filter.latitude, [FILTER_NAME.longitude]: filter.longitude, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData.resources, filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                }
                setIsLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [useQueryData, filter]
    );

    // Update based on prop changes (for properties modal)
    useEffect(
        () => {
            setData(nodeData);
            setIsLoading(loading);
        },
        [nodeData, loading]
    );

    if (filter.provider === PROVIDER_TYPES.AWS) {
        typeLabel = AWS_TYPES_TO_LABEL_MAP[filter.type];
        if (filter.type === AWSSupportedTypes.COMPUTEINSTANCE) {
            metrics = true;
            headerSublabel = data?.attributes?.InstanceType ? `${STRINGS.cloudim.topology.modal.subLabels.instanceType} ${data.attributes?.InstanceType}` : "";
        }
    } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
        typeLabel = NETIM_TYPES_TO_LABEL_MAP[filter.type];
    }

    return (
        <DataLoadFacade className="d-flex flex-wrap" loading={awsQuery.loading || netImQuery.loading || isLoading} loadingText={STRINGS.loading + STRINGS.loadingTextIndicator} error={awsQuery.error || netImQuery.error} data={data && !isLoading}>
            {data &&
                <div className={"propertiesContainer"+ (className ? " " + className : "")}>
                    <div className="header">
                        <div className="profile">
                            <div className={"icon" + (filter.provider ? " " + filter.provider : "")} title={typeLabel}>
                                <Icon iconSize={40} icon={icon} />
                            </div>
                            <div className="details">
                                <span>
                                    {data.label}
                                </span>
                                <span className="text-secondary display-9 font-weight-500">
                                    {typeLabel}
                                </span>
                            </div>
                        </div>
                        <div className="subLabels">
                            {headerSublabel &&
                                <span>
                                    {headerSublabel}
                                </span>
                            }
                            {/* Modifying from UTC to local time */}
                            {data?.timestamp &&
                                <WrapInTooltip tooltip={<>
                                        <div>
                                            {`${STRINGS.cloudim.topology.modal.lastCollected} ${formatToLocalTimestamp(new Date(data.timestamp + "Z"), TIME_FORMAT.DISPLAY_TIME_FORMAT)}`}
                                        </div>
                                        <div>
                                            {`${STRINGS.cloudim.topology.modal.lastCollectedInfo}`}
                                        </div>
                                    </>}
                                >
                                    <Icon className="mr-1" icon={IconNames.TIME} />
                                    <span>
                                        {`${STRINGS.cloudim.topology.modal.lastCollected} ${getElapsedTimeInfo(new Date(data.timestamp + "Z"), "standard")} ${STRINGS.cloudim.topology.modal.timeAgo}`}
                                    </span>
                                </WrapInTooltip>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="body">
                        {metrics ?
                            <Tabs>
                                <Tab id={STRINGS.cloudim.topology.modal.tabs.metrics} title={STRINGS.cloudim.topology.modal.tabs.metrics} panel={<MetricsTab type={filter.type as AWSSupportedTypes} attributes={data.attributes} />} />
                                <Tab id={STRINGS.cloudim.topology.modal.tabs.attributes} title={STRINGS.cloudim.topology.modal.tabs.attributes} panel={<AttributesTab attributes={data.attributes} />} />
                            </Tabs>
                            :
                            <AttributesTab attributes={data.attributes} />
                        }
                    </div>
                </div>
            }
        </DataLoadFacade>
    );
};

export default CloudIMPropertiesContainer;

/** This module contains the GraphqlService that is used to connect to DAL and run the 
 *  GraphQL queries
 *  @module
 */
// import {
//     ApolloClient,
//     InMemoryCache,
//     NormalizedCacheObject,
//     ApolloClientOptions,
// } from "@apollo/client";

// import { AuthServiceProvider } from "providers/AuthServiceProvider";
// import { AppAuthService } from "./AppAuthService";

// class GraphqlService {
//     private BASE_GRAPHQL_URI: string;
//     private authService: AppAuthService;

//     constructor(base_graphql_uri: string) {
//         this.BASE_GRAPHQL_URI = base_graphql_uri;
//         this.authService = AuthServiceProvider.getService();
//     }

//     public async getApolloClient() {

//         const authToken = await this.authService.getToken();

//         const apolloClientOptions: ApolloClientOptions<NormalizedCacheObject> = {
//             cache: new InMemoryCache(),
//             // uri: '/graphql?clientId=apim-rvbd-viz-services',
//             uri: this.BASE_GRAPHQL_URI,
//             headers: {
//                 authorization: "Bearer " + authToken,
//                 "client-name": "Affogato [web]",
//                 "client-version": "1.0.0",
//             },
//         };

//         var client = new ApolloClient(apolloClientOptions);
//         client.link.set

//         return new ApolloClient<NormalizedCacheObject>(apolloClientOptions);
//     }
// }

// // const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
// //   cache: new InMemoryCache(),
// //   uri: '/graphql?clientId=apim-rvbd-viz-services',
// //   headers: {
// //     authorization: localStorage.getItem('token') || '',
// //     'client-name': 'Affogato [web]',
// //     'client-version': '1.0.0',
// //   }
// // });

// export { GraphqlService };

import { DocumentNode, print } from 'graphql';
import { addTypenameToDocument } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink, from } from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { setContext } from "@apollo/client/link/context";
// import { createHttpLink } from "apollo-link-http";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { sha256 } from 'crypto-hash';
import hashedQueries from '../../graphql/extracted_queries.json';
import { ApiService } from './ApiService';

export const GRAPHQL_API_ENDPOINT = "/api/affogato/dal/2.0/graphql";
const authService = AuthServiceProvider.getService();

const httpLink = createHttpLink({
    uri: () => {
        if (ApiService.USE_REGION) {
            const region = authService.getRegion();
            return `/api/iq/${region}/dal/2.0/graphql`;    
        } else {
            return GRAPHQL_API_ENDPOINT;
        }
    }
});

const authMiddleware = setContext((operation) =>
    authService.getToken().then((token) => {
        const tenantId = authService.getTenantId();
        return {
            headers: {
                authorization: token ? "Bearer " + token : null,
                "client-name": "Affogato [web]",
                "client-version": "1.0.0",
                "x-tenant-id": tenantId
            },
        };
    })
);

// Use this link chain for the automatic persisted queries
const linkChain = createPersistedQueryLink({ generateHash: generateHash }).concat(from([authMiddleware, httpLink]));
// Use this link chain to transmit the full query every time.
//const linkChain = from([authMiddleware, httpLink]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: linkChain,
    cache: new InMemoryCache({
        typePolicies: {
            GenericValue: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            GenericIdValueOfString: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            GenericNameValueOfDouble: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            Dataset: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            PriorityReason: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            Query: {
                fields: {
                    summary: {
                        merge(existing, incoming) {
                            // Without this we get a warning about an issue merging the summary object.  The summary
                            // object has no id and should not be cached.  Adding the merge function is equivalent to what 
                            // happens if there is no custom merge function but causes the error to go away.
                            // Link: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
                            return incoming;
                        },
                    },
                },
            },
            InnerError: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
            NodeRun: {
                // Do not cache the id, value pairs, the ids repeat
                keyFields: false,
            },
        }
    })
});

export { client };

/** replaces the default generateHash in createPersistedQueryLink.  This version adds the typename to
 *      the query and then checks the extracted_queries.json file for the hash, if it exists it returns it,
 *      if it does not exist it generates a new hash (the hash is always the same).
 *  @param query the DocumentNode with the query.
 *  @returns a String or the promise that, when resolved, will return the sha256 hash.*/
function generateHash(query: DocumentNode): string | PromiseLike<string> {
    const queryWithType = addTypenameToDocument(query);
    const queryText = print(queryWithType);
    const hashedSha256 = (hashedQueries ? hashedQueries[queryText] : undefined);
    return (hashedSha256 ? hashedSha256 : sha256(queryText));
}

import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Classes, Divider, Intent, Spinner, SpinnerSize, TextArea } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';
import './AddAuthenticationModal.scss';
import { ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService';
import { AuthenticationMethodTypeEnum } from 'utils/services/ThirdPartyIntegrationApiService';
import { Form, SelectField } from 'components/common/form';
import { replaceTemplateInVerifyUrl } from './TestQueryPanel';

export interface IVerifyResponsePanelProps {
	alluvioEdgeData?: any;
	query: any;
	profile: any;
	sendRequestTo?: any;
	isEdit: boolean;
	hasClientCertificateSet: boolean;
	connectorCustomProperties: Array<any>;
    onChangeSendRequestTo: (event) => void;
	setVerified: (verified: any) => void;
}

const VerifyResponsePanel: React.FC<IVerifyResponsePanelProps> = (props) => {
	const [httpResponse, setHttpResponse] = useState<string>('');
	const [httpError, setHttpError] = useState(false);
	const [requestLoading, setRequestLoading] = useState(false);
	const [selectedAlluvioEdge, setSelectedAlluvioEdge] = useState(props.sendRequestTo || null);

	const handleTestClick = () => {
		let authMethod = typeof props.profile.authenticationMethod === 'number'
								? props.profile.authenticationMethod : AuthenticationMethodTypeEnum[props.profile.authenticationMethod];
		let sensitiveData = props.profile.sensitiveData;
		if ('awsSignatureVersion' in sensitiveData) {
			sensitiveData = {...sensitiveData}
			delete sensitiveData.awsSignatureVersion;
		}

		// Omit optional client certificate fields if not enabled
		if (!props.hasClientCertificateSet) {
			sensitiveData = {...sensitiveData}
			delete sensitiveData.clientCertificate
		}

		if (props.profile.sensitiveData?.awsSignatureVersion === 'AwsSignatureV4A') {
			authMethod = 5;
		}

		const verifyRequestBody = {
			authenticationMethod: authMethod,
			sensitiveData,
			edgeDeviceId: selectedAlluvioEdge,
			...props.query,
			verifyUrl: `${replaceTemplateInVerifyUrl(props.query?.verifyUrl, props.connectorCustomProperties)}`
		}
		
		setRequestLoading(true);
		const request = props.isEdit ? 
							ThirdPartyIntegrationService.verifyInstalledAuthProfile(props.profile?.id, verifyRequestBody): 
							ThirdPartyIntegrationService.verifyAuthProfile(verifyRequestBody);

		request.then(
			(response: any) => {
				// Response status 204, has no response body
				if (response === "") {
					const noContentCode = "AUTHENTICATION_PROFILES_VERIFY_PROFILE_THIRD_PARTY_RESPONSE_NO_CONTENT";

					setHttpResponse(`Status Code 204 \n${STRINGS.thirdPartyIntegrations.success[noContentCode]}`);
					setHttpError(false);
					props.setVerified({
						verified: true,
						time: Date.now()
					});
					setRequestLoading(false);

					return;
				}

				if (response.responseStatusCode >= 200 && response.responseStatusCode < 300) {
					setHttpError(false);
					props.setVerified({
						verified: true,
						time: Date.now()
					});
				} else {
					setHttpError(true);
					props.setVerified({
						verified: false,
						time: Date.now()
					});
				}
				setHttpResponse(response.responseStatusCode + '\n'
								+ response.responseBody);
				setRequestLoading(false);
			},
			(error: any) => {
				const data = error.response?.data;
				let code = data?.code || "";

				// Response status 500, has the error code as response body
				if (+data?.responseStatusCode === 500) {
					code = data?.responseBody;
				}

                const innerError = data?.innererror;
                const message =  STRINGS.thirdPartyIntegrations.errors[code] ?
                    STRINGS.formatString(STRINGS.thirdPartyIntegrations.errors[code], innerError) :
                    data.message || data?.responseBody;
				setHttpResponse(error.response.status + '\n'
								+ message);
				setHttpError(true);
				props.setVerified({
					verified: false,
					time: Date.now()
				});
				setRequestLoading(false);
			}
		)
	}

	function selectedAlluvioEdgeChangeHandler(newValue) {
        if (props.onChangeSendRequestTo) {
            props.onChangeSendRequestTo(newValue);
        }
        setSelectedAlluvioEdge(newValue);
    }

	function getSelectAlluvioEdgeOptions() {
		return props?.alluvioEdgeData?.edges?.filter(item => props?.profile?.edgeDeviceIds?.includes(item.id)).map(item => {return item.name}) || [];
	}

	function getSelectedAlluvioEdgeName() {
		return props?.alluvioEdgeData?.edges?.filter(item => item.id === props.sendRequestTo)?.[0]?.name;
	}

	return (
			<div className={classNames(Classes.DIALOG_BODY)}>
				<p>
					<b>
						{
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.verifyResponse.title
						}
					</b>
				</p>
				<Divider />
				<br />
			{props.profile?.edgeDeviceIds?.length > 0 && <div className='d-flex flex-column'>
				<Form initialValues={
					{ send_request_to: props.sendRequestTo }
				}
					loading={false}>
					<SelectField
						label={
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.verifyResponse.sendRequestTo
						}
						name="send_request_to"
						required={true}
						onChange={(event) => {
							selectedAlluvioEdgeChangeHandler(props?.alluvioEdgeData?.edges.find(item => item.name === event.target.value)?.id || '');
						}}
						options={[STRINGS.thirdPartyIntegrations.addAuthProfile.panels
							.verifyResponse.selectAlluvioEdge].concat(getSelectAlluvioEdgeOptions())}
						value={getSelectedAlluvioEdgeName()}
					/>
				</Form>
			</div>}
				<div className="d-flex flex-column">
					<Button className='align-self-start mb-3' intent={Intent.PRIMARY} onClick={handleTestClick}
						disabled={!props.query.verifyUrl
							|| !props.query.verifyRequestHeaders
							|| Object.keys(props.query.verifyRequestHeaders).length === 0
							|| (props.profile?.edgeDeviceIds?.length > 0 && !selectedAlluvioEdge)}>
						{STRINGS.thirdPartyIntegrations.addAuthProfile.panels.verifyResponse.runQuery}
					</Button>
					{!requestLoading ?
					<TextArea
							className={httpError ? 'http-error' : 'http-success'}
							rows={11}
							readOnly={true}
							value={httpResponse}
					/> : <Spinner size={SpinnerSize.STANDARD}></Spinner>}
				</div>
			</div>
	);
};

export { VerifyResponsePanel };

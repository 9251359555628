import React from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { HELP, STRINGS } from "app-strings";
import { CloudIMGeoMapView } from "./views/cloudim-geomap/CloudIMGeoMapView";
import { FeatureFlag } from "components/common/feature-flag/FeatureFlag";
import CloudIMLegal from "./views/cloudim-legal";

const CloudIMPage = (): JSX.Element => {
    return (
        <FeatureFlag flagName="cloudim">
            <CloudIMLegal />
            <PageWithHeader
                name={CloudIMPage.name}
                title={STRINGS.cloudim.pageTitle}
                showGlobalFilters={false}
                className="overflow-hidden"
                addPadding={false}
                showTimeBar={false}
                helpInfo={HELP.theaterView}
            >
                <CloudIMGeoMapView />
            </PageWithHeader>
        </FeatureFlag>
    );
};

export default CloudIMPage;

/** This module contains the types for the navigator component.
 *  @module
 */
import { LayoutConfig } from "components/common/vis-framework/layout/Layout.type";
import { WidgetConfig } from "components/common/vis-framework/widget/Widget.type";

/** defines the navigator template configuration type. */
export type NavigatorConfig = {
    /** a String with the id of the navigator template. */
    id: string;
    /** a String with the name of the navigator template. */
    name: string;
    /** a String with the description of the navigator template. */
    description: string;
    /** the navigator template end time in milliseconds. */
    endTime?: number;
    /** the current navigator template layout. */
    layout: LayoutConfig;
    /** the array of WidgetConfigs. */
    widgets: WidgetConfig[];
    /** a String with the navigator template creation time. */
    createdTime?: string | number;
    /** a String with the username of the user that created the navigator template. */
    createdByUser?: string;
    /** a String with the navigator template last update time. */
    lastUpdatedTime?: string | number;
    /** a String with the username of the user that last updated the navigator template. */
    lastUpdatedUser?: string;
    /** an optional boolean value that specifies if this navigator template is a factory navigator template. */
    isFactory?: boolean;
    /** a boolean value, true if the navigator template is active. */
    active?: boolean;
}
/** defines the widget configuration type. */
export interface NavigatorWidgetConfig extends WidgetConfig {
    /** the query config that is used to run the queries for this widget. */
    queryConfig: QueryConfig;
}

/** this interface defines the query configuration. */
export interface QueryConfig {
    /** I don't think we should do it this way, but right now I am trying to match the DO node so we don't need to make as many changes. */
    properties: {
        /** the object type, such as "tcp_connections" or "network_interface". */
        objType: string;
        /** the limit of items to return. */
        limit: number;
        /** the duration of the query. */
        duration: number;
        /** the filters that are to be applied. */
        filters?: FilterEntry[];
        /** the top by specification. */
        topBy: {id: string, direction: string}[];
        /** a string with the group by key ids. */
        groupBy?: string[];
        /** a string array with the keys to query. */
        keys?: string[];
        /** a string array with the metric ids. */
        metrics: string[];
        /** the compare to string.  If undefined there is no comparison. */
        comparedTo?: string;
        /** a boolean value, true if this is a time series query, false otherwise. */
        timeSeries: boolean;    
    }
}

/** this interface defines the filter configuration type. */
export interface FilterEntry {
    /** the type of filter entry. */
    type: FILTER_TYPE;
    /* the array of values for the filter type. */
    values: string[];
    /** the operator that is to be used in the filter. */
    operator?: FILTER_OPERATORS;
}

/** this enum specifies all the valid filter types. */
export enum FILTER_TYPE {
    /** this is the key and value for the application name filter type. */
    "app_name"          = "app_name",
    /** this is the key and value for the user name filter type. */
    "user_name"         = "user_name",
    /** this is the key and value for the interface name filter type. */
    "interface_name"    = "interface_name",
    /** this is the key and value for the process id filter type. */
    "process_id"        = "process_id", 
    /** this is the key and value for the process name filter type. */
    "process_name"      = "process_name",
    /** this is the key and value for the machine id filter type. */
    "machine_id"        = "machine_id",
    /** this is the key and value for the os type filter type. */
    "os_type"           = "os_type",
    /** this is the key and value for the os version filter type. */
    "os_version"        = "os_version",
    /** this is the key and value for the client ip filter type. */
    "client_ip"         = "client_ip",
    /** this is the key and value for the client port filter type. */
    "client_port"       = "client_port",
    /** this is the key and value for the server ip filter type. */
    "server_ip"         = "server_ip",
    /** this is the key and value for the server port filter type. */
    "server_port"       = "server_port",
    /** this is the key and value for the host filter type. */
    "network_host"      = "network_host",
    /** this is the key and value for the client host filter type. */
    "network_client"    = "network_client",
    /** this is the key and value for the server host filter type. */
    "network_server"    = "network_server",
    /** this is the key and value for the application filter type. */
    "application"       = "application",
    /** this is the key and value for the location filter type. */
    "location"          = "location",
    /** this is the key and value for the client location filter type. */
    "client_location"   = "client_location",
    /** this is the key and value for the server location filter type. */
    "server_location"   = "server_location",
    /** this is the key and value for the interface filter type. */
    "network_interface" = "network_interface",
    /** this is the key and value for the device filter type. */
    "network_device"    = "network_device",
    /** this is the key and value for the dscp filter type. */
    "dscp"              = "dscp",
    /** this is the key and value for the protocol filter type. */
    "protocol"          = "protocol",
    /** this is the key and value for the vantage point filter type. */
    "data_source"       = "data_source"
}

/** this enum specifies all the valid filter operators. */
export enum FILTER_OPERATORS {
    /** this is the key and value for the equals operator. */
    "EQUAL"         = "EQUAL",
    /** this is the key and value for the starts with operator. */
    "STARTS_WITH"   = "STARTS_WITH",
    /** this is the key and value for the ends with operator. */
    "ENDS_WITH"     = "ENDS_WITH",
}

/** the keys that are supported in the interactivity. */
export const FILTER_KEYS: string[] = Object.values(FILTER_TYPE);

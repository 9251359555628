import React, { useEffect, useState } from 'react'
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { FILTER_NAME, parseTimeFromDALToUnix, useQuery, useStateSafePromise } from 'utils/hooks';
import { STRINGS } from 'app-strings';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import TimelineChart from 'components/common/timeline-chart/TimelineChart';
import moment from 'moment';
import { Card } from 'components/reporting';
import { IconTitle } from 'components/common/icon-title/IconTitle';
import { INDICATOR_TYPE, SIZE } from 'components/enums';
import './TimelineOutputView.scss';
import { IndicatorMapping } from 'pages/incident-list/Incident.type';
import { DataOceanMetadata } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils';
import { openModal } from 'components/common/modal';

export default function TimelineOutputsView({ incidentHeaderQuery, incident }) {
    const [executeSafely] = useStateSafePromise();
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();

    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(
            (response: DataOceanMetadata) => {
                setObjMetricMetaData(response);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely]);

    const incidentStrings = STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels.selectTrigger.triggers;

    const indicatorsQuery = useQuery({
        name: "PrimaryIndicatorForPrimaryIndicatorView",
        query: new Query(loader("../primary-indicator/primary-indicator-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId],
        timeNotRequired: true,
    });

    const runbookQuery = useQuery({
        name: "RunbookList",
        query: new Query(loader("../runbook-outputs-tab/runbook-list.graphql")),
        requiredFilters: [FILTER_NAME.incidentId],
    });

    // uncapFirstLetter (Should be in utils)
    const uncapFirstLetter = (string) => {
        return (string?.charAt(0)?.toLowerCase() + string?.slice(1)) || '';
    }

    // All types of events raw data
    const lifecycleEvents = runbookQuery?.data?.runbooks?.nodes?.filter(x => !x.entity?.kind?.includes("_")) || [];
    const runbookEvents = runbookQuery?.data?.runbooks?.nodes?.filter(x => x.entity?.kind?.includes("_")) || [];
    const indicatorMappings = indicatorsQuery?.data?.incidents?.nodes?.[0]?.indicatorMappings?.filter(x =>
        indicatorsQuery?.data?.incidents?.nodes?.[0]?.indicatorMappings?.some(y => y.isEarliest) ? !x.isEarliest : !x.isPrimary
    ) || [];
    const OGearliestIndicatorTS = indicatorsQuery?.data?.incidents?.nodes[0]?.earliestIndicator;
    const earliestIndicator = indicatorsQuery?.data?.incidents?.nodes[0]?.indicators?.filter(x => x.isEarliest) || [];
    const primaryIndicator = indicatorsQuery?.data?.incidents?.nodes?.[0]?.indicators?.filter(x => x.isPrimary) || [];
    const correlatedIndicators = indicatorsQuery?.data?.incidents?.nodes?.[0]?.indicators?.filter(x =>
        indicatorsQuery?.data?.incidents?.nodes?.[0]?.indicators?.some(y => y.isEarliest) ? !x.isEarliest : !x.isPrimary
    ) || [];
    const IQEvents = incidentHeaderQuery?.data?.incidents?.nodes || [];

    // Initiate an an object with 5Tuple keys that will hold arrays
    const fourTupleToIndicators = {};
    (indicatorMappings as IndicatorMapping[])?.forEach(mapping => {
        const metric: string = mapping.metric;
        const entityId: string = mapping.entityId || "";
        const kind: string = mapping.kind;
        const sourceId: string = mapping.sourceId || "";
        const entityKind: string = mapping?.entity?.kind || "";
        fourTupleToIndicators[entityId + ":" + metric + ":" + kind + ":" + sourceId + ":" + entityKind] = [];
    });
    // Push arrays into the 5Tuple object
    correlatedIndicators?.forEach(indicator => {
        const metric: string = indicator.metric;
        const entityId: string = indicator.entity?.id || "";
        const kind: INDICATOR_TYPE = indicator.kind;
        const sourceId: string = indicator.entity?.source?.id || "";
        const entityKind: string = indicator?.entity?.kind || "";
        const arrayOfIndicators = fourTupleToIndicators[entityId + ":" + metric + ":" + kind + ":" + sourceId + ":" + entityKind];
        if (arrayOfIndicators) {
            arrayOfIndicators.push(indicator);
        }
    });
    // Creates an object with unique dates as key and an array of 5tuples as value
    const startTimesToFourTupleData = {};
    for (const fourTuple in fourTupleToIndicators) {
        const arrayOfIndicators = fourTupleToIndicators[fourTuple];
        let minStartTimeStr = "0";
        let minStartTime = Number.MAX_SAFE_INTEGER;
        for (const indicator of arrayOfIndicators) {
            const startTimeStr = indicator.startTime;
            const indicatorStartTime = parseFloat(startTimeStr || "0");
            if (indicatorStartTime < minStartTime) {
                minStartTime = indicatorStartTime;
                minStartTimeStr = startTimeStr;
            }
        }
        if (!startTimesToFourTupleData[minStartTimeStr]) {
            startTimesToFourTupleData[minStartTimeStr] = [];
        }
        startTimesToFourTupleData[minStartTimeStr].push(fourTuple);
    }
    // Match each 5tuple with its matching array and filter it.
    const matchTuples = (times, tuples, filterStartTime) => {
        const matchedObjects: Array<any> = [];
        times.forEach(timeKey => {
            if (tuples[timeKey]) {
                const filteredObjects = tuples[timeKey].filter(obj => obj.startTime === filterStartTime);
                matchedObjects.push(...filteredObjects);
            }
        });
        return matchedObjects;
    }

    const correlatedIndicatorsMap = () => {
        const mergedArray = Object.keys(startTimesToFourTupleData)?.filter(x => x !== '0').map(timeKey => ({
            seriesType: 'indicators',
            eventType: 'correlatedIndicator',
            timestamp: parseTimeFromDALToUnix(timeKey),
            nameMap: STRINGS.timelineOutputsView.namedMap.correlatedIndicator,
            value: {
                startTime: timeKey,
                cluster: startTimesToFourTupleData[timeKey].length,
                allIndicators: startTimesToFourTupleData[timeKey].map(indicator => indicator.split(":").at(-1)),
                mappedIds: matchTuples(startTimesToFourTupleData[timeKey], fourTupleToIndicators, timeKey)
            }
        }))
        return mergedArray
    }

    // Sorting & Mapping Series
    const allEventsSorted = [
        ...lifecycleEvents?.map(lfcEvent => ({
            seriesType: 'lifecycle',
            eventType: 'lifecycleEvent',
            timestamp: parseTimeFromDALToUnix(lfcEvent.timestamp),
            nameMap: lfcEvent.entity?.kind,
            value: lfcEvent
        })) || [],
        ...runbookEvents?.map((iqEvent, i) => ({
            seriesType: 'iqEvents',
            eventType: 'runbookIncident',
            timestamp: parseTimeFromDALToUnix(iqEvent.timestamp),
            nameMap: i === 0 ? STRINGS.timelineOutputsView.namedMap.runbookExecuted : STRINGS.timelineOutputsView.namedMap.runbookRerun,
            value: iqEvent
        })) || [],
        ...primaryIndicator?.map(indicator => ({
            seriesType: 'indicators',
            eventType: 'primaryIndicator',
            // If earliestIndicator exists and it matches the OG timestamp, use its timestamp, otherwise use the OG timestamp (the earliestIndicator property)
            timestamp: parseTimeFromDALToUnix(
                (earliestIndicator && earliestIndicator[0]?.timestamp ===  OGearliestIndicatorTS) ? 
                earliestIndicator[0].timestamp : 
                OGearliestIndicatorTS 
            ),
            nameMap: STRINGS.timelineOutputsView.namedMap.primaryIndicator,
            value: indicator
        })) || [],
        ...correlatedIndicatorsMap(),
        ...IQEvents?.flatMap(iqEvent => {
            const startEvent = {
                seriesType: 'iqEvents',
                timestamp: parseTimeFromDALToUnix(iqEvent.createdAt),
                value: iqEvent,
                nameMap: STRINGS.timelineOutputsView.namedMap.incidentStart
            };
        
            const endEvent = iqEvent.endTime ? {
                seriesType: 'iqEvents',
                timestamp: parseTimeFromDALToUnix(iqEvent.endTime),
                value: iqEvent,
                nameMap: STRINGS.timelineOutputsView.namedMap.incidentEnd
            } : null;
        
            return endEvent ? [startEvent, endEvent] : [startEvent];
        })
    ]
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((event, i) => ({ ...event, sortIndex: i }));

    // Remapping Series to match highchart format and config
    const mappedSeries = allEventsSorted.reduce((acc, curr) => {
        if (!acc[curr.seriesType]) {
            acc[curr.seriesType] = {
                name:
                    curr.seriesType === 'lifecycle' ? STRINGS.timelineOutputsView.series.lifecycle :
                        curr.seriesType === 'iqEvents' ? STRINGS.timelineOutputsView.series.iqEvents :
                            curr.seriesType === 'indicators' ? STRINGS.timelineOutputsView.series.indicators : '',
                marker: {
                    fillColor:
                        curr.seriesType === 'lifecycle' ? 'orange' :
                            curr.seriesType === 'iqEvents' ? '#0080FE' :
                                curr.seriesType === 'indicators' ? 'red' : ''
                },
                legendIndex: curr.seriesType === 'iqEvents' ? 2 : curr.seriesType === 'indicators' ? 1 : 3,
                className: curr.seriesType,
                data: []
            };
        }
        // Extract the properties of curr.value and create the new structure
        const mappedEventPoint = {
            x: curr.sortIndex,
            marker: {
                width: 20,
                height: 20,
                symbol: curr.seriesType === 'lifecycle' ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAABcRAAAXEQHKJvM/AAAEL0lEQVRYhbWZvW7bVhiGHxKFFneglmhRAcqLuwiQm8VLYxnJ0qW2gE7RUOYKYl+B5StwfAV2Bs2SunQKpKRLl6ACPHmJaFSLvUhDtXhhh4+0KR6ewx8lLyBIIg/Jhzzn+6VFSQV9WkAb2AdagKsZOg0/H4Gh1WVZ5npWQTgH8IC3BrAsDYH3VpdhkYNygwZ9egigU4xLqylwYnWZ5BmcCRpO8SUyvd9CQ+BN1pIwggZ9PASynGpt+X5YwmJqGukDHauLdpAWNOhzDhyX4aN+BHuXUImtkpUPnzom4CVwoINNBd0IstqCX/5J3/ewhD8a8p0uLaydAumVhgRonur3VRxoeKajHWAc9FWPsgYaMxyztj04nMHrAF6N16f4Wdt87I9vYUvhSMIOjKCZkBUHXgxk/UUXe9aGnQITsOXK0tj2TKNaoTtUQcMdehdUceDlWAzFpHkOP15x5Gafn5tGncaXgB1COogzN0NWNfdxP3n6fXNhMpZ17RxDs2eEXQNFwqI+4uxdpkPeT2DUgLsY6GIKHw7EHcWlc0vNU9MsedFTtQCCPjN0sbt+JOsyqeszuO7pLiCqtmQ2/vMFvNaGnwfrxgey789d3UycWV16VmjpGseHWHfSSj+fwM07M6QJ/uVYhdXfuG91adhIqpaubU+FnA/LQ4Isgb/fqNt3tCbiBn1cG8kn01U/VLd9PimDt675UPUOFce0Vts2JpeUPPDLlWokZXVzoW6raZ+Za6MzojSHPB+Vg0rT3US9aZ37g30l1gMCuZcSpJqnqhFsorhbA2P4VUG3XPhJEzGqLXPSUVSr29xDVdD6kfmpmbOfYopHtLT/MX2nbMma2q899R8Onqbc4PZU0Kw4nTeO59XdRF2rqnwbEtl0VvYzuyrNpMicl8Z1q4Ku/PTIARJVrs/Kg4EY5KuxJN2HM/htkZVBAUwsbaVZbUlY+96V//+ONgud0TnT4jzIufVRr2qFuehiM4KcejEwJ96jRlrkG1pdOnZY+Odvr9Ta2S5Mp6zqIL08GcGT1b8HMs6CrK3IlURZkLmx8KRybu3xIdoAYcMq+4rxEBfV7/FCTwfY7MGvs2ws1fVdRK2exwZE0KcNjI0neh3o9638MNGIhcXafnb5HIdcb074wK4CGsIOMC2B5+fFSuO8eljCX52k4+/EW5NJUAcpS1ztSXeON8+i7icCV3Hg7mNanvvO6rLmq5TeU1hDjTFVpVuuwJqbCKpWvgSML1emUVOry25yo65Jlg0LAlw/gh8O9WtxMZUnOB/lielTpEmmWJWp7ZgPNqmoJxqVyPmlhYTsRq6LNKy+Vbc5krImk8rVww/7Ul8xtX+Uj/TxMyNjkZcNLgLrlaWKaQlcIE8yV4Jb6PUNPAJ7wO8Uf4UzRGJ34fdNhUHjCqHbCHBaUe4Dt8AEcTuly4P/AcMfYcEdXd0YAAAAAElFTkSuQmCC)' :
                    curr.seriesType === 'indicators' ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAYAAAAqAaJlAAAACXBIWXMAABcRAAAXEQHKJvM/AAAC+ElEQVRYhb1Z0XWjMBAc+10BdHDqIHRw6uDoIKSCpIM4FfBcAXEFdgfGFdgd4FRAOpj7EMRYSLAS+Oa9fbZBSMNotbuSV5gBAhrG/gBIASSephWAC4ATgGoFfM8ZVwwCisCGQE2AkVa2L/owkgmBYgZBlx0JqKWJZgSahYn2bbMU0aXV9NmZfp8XES3/E9E+4fQxRLUm65okyaJYinATpLBY0abhHbR+qEusHURzGBtHngOJ1Z/WYkEmkAIoRlvQxFDZqj+fOcB+v7QPZ2NkZdOv9ZAoSR6PS5Otnf7bqirrpCzdZMmlyZKuGCxWVSk/UdLcX1jdjuO6JZpAsqgAs7DGoJSomwCozne7aOB3ZBuvr+P3fREhz4GyBN7exEP18PfnG4G9aEryfNwFSOPPU8+FJ5CmT7YWPeQKV/Y1V0RwPReeQNKu9Jtu7ApXZUluNsPrkgVZ12SShJDN1pAWDs/Pw2vbLXC9Dq/3F5nPx5UC3t9FQ7dIQUBHhavz2a94f4q7QscHuTtsfnnfOstuuf/padhmuzWfVTW8p7W5nmXToawsgd3u9vtyAQ4Hd9uBskoNqykbTXP/1rZ6XURwZTrXYrPhTtubQdV1p6gPnaodbL9VyvRhJ5CqAl5exvsGzMw4ZmQNe1v8Ldglf37e/z6dhoNljjyz25lp/viYHsO1cAFgIPl+75/+PJclC9s1muY+VBVF2Bjd1p2mMg8N0uMx2IYrs4WZ6sjO38FOIcvm9F/33SCbTXYsntb1XFULoK26VsABgMejhfAtCMAfN+XYAfcbxp2noQx2ROjDDnVhqFbmUO8GmoIm/ojIVz52aTnedMfxR9n2GDJeAp8bzFe18t7lnDDmQlgZ2LeGUyeMBNJod/DVCHGWi7QnkEcNYBfiaRpLtJR7yhzCWhvS8VvyMKKzCcdbHNEeYR3tw2EWtUd3EU74uIPluANkAWlN86fFEiRrSlf8TNJpq3QdQXLPkBOgHlZLEIdJib/hns4rgC9MZSMB/gHVUoQM7pA3iQAAAABJRU5ErkJggg==)' :
                        curr.nameMap === 'Incident End' ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAYAAAAqAaJlAAAACXBIWXMAABcRAAAXEQHKJvM/AAACx0lEQVRYhcWZMXLbMBBFHzEu3IVlOvMG0Q0sn0DOCSJVKZWcwPAJYp9A8gkil6ksd+nMMl2YziVLV3SKJWRSIkCQJqg/oxmSWhBvFoslsIjoK13EwCVwDkzKX5MyIAUegS1apX27jDq30MUUWCKgfZQBt8AarfIuDf1hBfIKmHbpwKEcuEUr7dugHVaG+wr41hvLrRRY+ISHG1YXE2CFPR6H1AKt1i4DO6yAPgDxsExOrdFqYfuzGfY4oEZW4ENYXSTAE8cBNWoMCdVg+JPjggKsytGtqQ6rC804k8lHq/0Hb2Egw/93RBgfXVfzcNWzV+OztGpZ5nkATgDj1fmYFPMJJHFE/vLKzW+rWYxw3YCBHRl0NYuY72ZGxNkH+P7r1Wa+pIQ1YTALi/emOqho8tHZJDGZQZUhMEoGaAIFyNrXXpcgnp0OzNQoG2j67AwBo3MQ2GRosH25QC/uXslfWl+RgMB+GpitpgFAoQIb7NM6EOhOTWuDA8WnME1aZ21NQ4Oii+lJm018Ck9fI5LS/+sUFvfuCTE4qChv9ax8aer3q5l9zR4IFLRKFbLbtKrp5TbgYKClFPDPZbD5I53tax84MGgKsjbY4lhx5S/S2cOX6GCCCVxUuQ4CuoNV5sIlA2zzcGBQkGoOqqyKbNqsXcD7GhgUZPR3efbep4UPcADQDVplYGBlJ+lVd3IBBwAFuDMX1Tx769u6CTgQaIZWuxCtbhhjpF6QdHnbtLROn5tz8jt1gVZbc1PP7FIpfBi8y37aoNXn6oO9uoHaUu53jqwMOCgh2WpdTxyv2JEjw3+Q/20LmQs8PhYBZAUFd8kzRuJ3LA87QcGv8v2DcFVvI6/qt9+ZgmSJFWE2l9e+5wpdDkBixMNLhtm3rRHQzLdB96MlAF3MkSpO1+OlDPl8rrtAGvWDNRJvT5BCyRnNYfKIQG77AFb1H7K8KKZcvwyOAAAAAElFTkSuQmCC)' :
                            curr.eventType === 'runbookIncident' ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAYAAAAqAaJlAAAACXBIWXMAABcRAAAXEQHKJvM/AAADxElEQVRYhc2ZvXLbOBSFP3FSqFuW6swu25mpkk5yldLOE0iptpTzBIKfQOtyK0tPEPoJLJVbWem4HdO5ZOmK2uIK4h9IAhgXPjOakSjg4vDg8OICHOELVYTADTAF4tPHhAw4AHtghwoOvkOOnHuoYgYsEaI+yIB7YIMKcpeO9mSF5JpuBV2RA/eoQNl2GCYr0/2Av5JDOADfbewR9P6rihh4xoHozZ/wcO3kLhlDFYuhht1khegTENmOGoVCdBHD7RfbXmc8oIqHvgZmCUqioctoz3+NiCfl70//HDm8uEQA5MH7bvqjrawqIjyIqlmdKDjbQWPRZQmTDX7iSDQKYTVtX48nXnYAsUQr69TJqkLhkZrWX7sVXE1HhGPXiIBkoBpKsjL9K9eIs0gyQBfCMdx+8bJDfBLvjKqyzkQB5pfDRJaf8VV3ecrzgCYrqi5cI4VjWDRMc3iBXdZu16d+3xBVXlrZhanlEJpEk1TS1dVWPlXYzEAHlvqLJjv3iTK9qBO4/7ck2FR3FnlbIdKZIThZIPKJMouav0vyzZzbdc0SNwAfgJlP73DcVmo1hYs/RuSvbYuA3MwuO7b/GMYUhGzk07tLJRPJN0AE4lnD2vPuEMFQifjO4Ew2cqoa3jCGKmInsvFEykDAp/Q7Q9e8jsitycYTeJpLURJPIH91HuycCcKxB2EVZAGy2xzE/LKsnnQmaCb+IWR5vf9q6raqBcBvm4bVBUCvXC5WyF+FbDVOFFr79wBCdjfUUk+9hh5w/9s+wetZuP5YV7O5CnbgTHZwC9wMGIVC3sUGj//JjTWrr2Z90YE9QHA6FUn6WpoCzi9lWU1SK64kqXnaLZXdQZlnH/tamgJqhbRifUhSTvVC+6YtfJugggykNgAVbFDFGsNGselXkAdFK5qkkOX9hHUWOLwcSdJRywqzCDbdZtzqLx8qF+8xbG20qprg9lf9LCB/tfdukkKSHs87h+uPQnx6MWJzMN5whgrOFi3nRfY6zzSqsFkkhGzS1NO8Ps3bX8c+xYBy5jpu+AoV7NpkhfANcm7gheOqHu5qe3ReOCpIUMG36oXGuUGQAH/7RtfefANkQOsIyXB8FPzAIveaUFXRxcsN5MA300FzVyFzhQfhahqzzb8N5IhPjWObycpdORNO0vJBvNs777V6iYLdyfcauLUdMZ5IkndU1ur0265GkyyxxnNzOYA72/cKLi9AQkThJY5Hoh3YIEQz2w4+r5b0+69r3F+KZMjyuXEhqeF9AAVo4jFyUHKB2SZ7hOTOh2AV/wNI9S420NmougAAAABJRU5ErkJggg==)' :
                                curr.nameMap === 'Incident Start/Created' ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAYAAAAqAaJlAAAACXBIWXMAABcRAAAXEQHKJvM/AAADuElEQVRYhb2ZrWLbSBSFPwkFim3YatGGxYtaFgUVxkGFidFCJ0/Q8RMkfQLbsCgyLIrMUlSVOagKMxQ0kguuZcuOZjQz6e5BtjQ/x2fu3D8HvAWq6gF94AzoAZFmZAYUwBxIUWHps13gQTACboArIPbZFJgAU1SYuUyyJ7sjOUSvoCsy4BYV5jaD7ciqKgHG+CvZhXtUeNs1qJusqhTwyTSkdwxXpwG9Y/kcHcnzfAlFCbPnNekCypVxpxy4RIWFH1lVjYFr3eskhk9nAUlsXAUQop+/wf3T2kS6BM51ZqEn20H07kPAzftukofIlzCYrcmX2iFawu1kO45+fBFw3XPmuWOzgvOpkXAB/HPo4sJXw1TV5z8kCmLTj1fB1rZbEAMPhw/3yYp7GutWSGLeTLRGdAQPH41XJkFVN80H+6NVdYf40lb8HAbEBg+bFXLz86WQOftTTsGgIOfTNVmhfV0Cf9XmsCOrqhj4qZuVxHJ0rSuu5NKki9fv6iPvHbevmy7g8stayxYYoUIF+2YwNM24+Ft/ZLdf24nC7jIVmmygf2LadcNLzHOP7LVphk6ZfAmTjmBZrmA016vX4acjJFnakBUPYIz3OrubPRs32kKnvCUuYKfsWddo08WyQUeo7UJDWUi6Rhsc+P8DVSU12U7vqSN7+ofdXqaLZHBdTfTCjcvqxPyl/YL0T/SXr4nhu3Zv4mDLUYhljpou0Lqfh4/mYDG+0Gdms2ejj23i9HVuYMD0R/vzOILv/waoZOf8oyNR/fFKn0uUq26310AUbKqAR5vRcSQh93dhNAeVWSubOSlblE5KGFGuJBF32T5EyglrmCKRCz5/c/a9L+Emo7Gu43+Huh6qwkZZcFT39quxjuqEh6oAeU127jKrLv58UJReqpaocEs2dZ19/6RP+0wYzb1OJYVt1hXmSJFmjXIl5uCCrPC29yns57Mj1xXShXVcB9x/3AZF3RPbkVXhBEd1QcoZG9w/eWduWxEPg4KzukUpkah7jJeq+UZE4FUpHk6Qzp4TVGZsWDCYebu6QfNLW7gd4BAkmoTa4GrXDYwOW0gtHZmwAC5dV86XYpdN1CW6ByZ1+d1EeyIjt2/Q+s6A0XzfHDyPP0eFrXvrsy6xXyeTaCo5yb0q2glwrntp00zuIU2y2HbH/onYqaOqo7ajb8K2TR8hnUVtH+wNKICBzZ8hbmm/qHyHRelugQJRc2I7wa9GkYp4iDQfYsfZKTBzIVnj7QWVqN1DSLd1dgrgBchc//c6xC93yWnqTn3CfwAAAABJRU5ErkJggg==)' : ''
            },
            name: curr.seriesType === 'lifecycle' ? (incidentStrings[uncapFirstLetter(curr?.nameMap)]?.label || '') :
                (curr.eventType === 'correlatedIndicator' && curr.value.cluster > 1) ?
                    `${curr.value.cluster > 1 ? curr.value.cluster : ''} ${STRINGS.timelineOutputsView.namedMap.correlatedIndicators}` : curr.nameMap,
            label: moment(parseTimeFromDALToUnix(curr.timestamp)).format('MMM Do, h:mm:ss A'),
            custom: { ...curr.value, cluster: curr.value.cluster, eventType: curr.eventType, nameIQ: curr.nameMap || undefined }
        };

        acc[curr.seriesType].data.push(mappedEventPoint);
        return acc;
    }, {});

    // Sequenced array with all Series in order
    const sequencedSeries = Object.values(mappedSeries);  
    
    function setTimelineModal(point): void {
        const { eventType, template, variant, startTime, endTime, mappedIds, id } = point.custom;
        const earliestIndicatorMappings = indicatorsQuery?.data?.incidents?.nodes[0]?.indicatorMappings?.filter(x => x.isEarliest)?.map(({ __typename, isPrimary, ...rest }) => rest);
        const primaryIndicatorMappings = indicatorsQuery?.data?.incidents?.nodes[0]?.indicatorMappings?.filter(x => x.isPrimary)?.map(({ __typename, isPrimary, ...rest }) => rest);
        const timeRange = {
            startTime: parseTimeFromDALToUnix(startTime) || 0,
            endTime: eventType === 'correlatedIndicator' ? parseTimeFromDALToUnix(mappedIds[0].endTime) : (parseTimeFromDALToUnix(endTime) || 0),
        };
        return openModal("timelineModal", {
            runbookId: id,
            mappedIds,
            eventType,
            template,
            variant,
            indicatorMapping: earliestIndicatorMappings[0] || primaryIndicatorMappings[0],
            objMetricMetaData,
            incident,
            timeRange,
            incidentId: incident.id,
            entity: (earliestIndicator[0]?.entity || primaryIndicator[0]?.entity),
            primaryIndicator: primaryIndicator[0],
            earliestIndicator: earliestIndicator[0],
            onClose: () => {},
        })
    }

    return (
        <>
            <IconTitle title={STRINGS.timelineOutputsView.title} size={SIZE.m} className="mb-2 font-weight-500" />
            <DataLoadFacade
                data={runbookQuery.data && indicatorsQuery.data && objMetricMetaData}
                loadingText={STRINGS.loading}
                loading={runbookQuery.loading || indicatorsQuery.loading || !objMetricMetaData}>
                <Card hideBorder hideShadow className={"mb-4"}>
                    <div className='position-relative'>
                        <TimelineChart
                            dialog={pointData => {
                                if (!pointData?.custom?.eventType) {
                                    return false
                                } else {
                                    setTimelineModal(pointData)
                                }
                            }}
                            incident={incident}
                            dataSeries={sequencedSeries}
                        />
                    </div>
                </Card>
            </DataLoadFacade>
        </>
    )
}

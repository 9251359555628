import React, { useState } from "react";
import { IntegrationErrorDetails } from "../types/IntegrationTypes";
import { Button, Collapse } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { LangEN, STRINGS } from "app-strings";

type Props = {
    error: IntegrationErrorDetails;
    indentLevel: number;
};

const IntegrationErrorItem = ({ error, indentLevel }: Props) => {
    const marginLeft = indentLevel * 8;
    const [collapsed, setCollapsed] = useState(indentLevel === 0);
    const translations: LangEN["thirdPartyIntegrations"] = STRINGS.thirdPartyIntegrations;
    let code = error?.code || "";
    const innerError = error?.innererror;
    const message =  translations.errors[code] ?
        STRINGS.formatString(STRINGS.thirdPartyIntegrations.errors[code], innerError) :
        error.message;

    return (
        <li key={`item-${error.innererror}-${indentLevel}`} style={{ marginLeft: marginLeft }} className="icon-and-label-div">
            <p>
                {message}{" "}
                {error?.details?.length && <Button key={`toggle-${error.innererror}-${indentLevel}`}
                    minimal
                    small
                    icon={IconNames.CHEVRON_RIGHT}
                    onClick={() => setCollapsed(!collapsed)}
                />}
            </p>

            {!collapsed && (
                <Collapse isOpen={!collapsed}>
                    {(error.details || []).map((innerError, index) => (
                        <ul key={index}>
                            <IntegrationErrorItem
                                error={innerError}
                                indentLevel={indentLevel}
                            />
                        </ul>
                    ))}
                </Collapse>
            )}
        </li>
    );
};

export default IntegrationErrorItem;

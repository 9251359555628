/** This module contains all the types for the incidents.
 *  @module
 */

import { INCIDENT_STATUS, INDICATOR_TYPE, PRIORITY } from "components/enums";
import { ImpactedItem, RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { Entity } from "utils/runbooks/EntityUtils";

/** defines the incident type.*/
export type Incident = {
    /** the id of the incident.*/
    id: string;
    /** a String with the description of the incident. */
    description?: string;
    /** the status of the incident for example new, open, dismissed, closed, ... */
    status?: INCIDENT_STATUS;
    /** the priority of the incident, for example Critical, High, Moderate, Low. */
    priority?: PRIORITY;
    /** the time at which the incident was last updated in seconds.nanoseconds. */
    //lastUpdated?: string;
    lastUpdatedAt?: string;
    /** the time at which the incident was generated in seconds.nanoseconds. The wall clock time at which the 
     *  correlation engine created the indicator. */
    //generated?: string;
    createdAt?: string;
    /** the time at which the incident was closed by the correlation engine in seconds.nanoseconds.  If it is not 
     *  closed the end time will be 0. */
    endTime?: string;
    /** a boolean value which indicates if the incident is ongoing.  This used to be determined by and 
     *  end time equal to null, but now the endTime will always be set. */
    isOngoing?: boolean;
    /** the time at which the incident had its earliest indicator in seconds.nanoseconds. */
    //earliestDetection?: string;
    earliestIndicator?: string;
    /** the time at which the incident had its latest indicator in seconds.nanoseconds. */
    //latestDetection?: string;
    latestIndicator?: string;
    /** the array of triggers for the incident, right now there should only be one item in this array. */
    //triggers?: Array<Trigger>;
    trackingEntity?: Entity;
    /** the list of indicators in the IndicatorMapping format. */
    indicatorMappings?: ComplexIndicatorMapping[];
    /** the indicators associated with this detection or trigger. */
    indicators?: Array<Indicator>;
    /** the list of impacted applications. */
    impactedApplications?: Array<ImpactedItem>;
    /** the list of impacted locations. */
    impactedLocations?: Array<ImpactedItem>;
    /** the list of impacted users. */
    impactedUsers?: Array<ImpactedItem>;
    /** an integer with the number of impacted applications. */
    impactedApplicationsCount?: number;
    /** an integer with the number of impacted locations. */
    impactedLocationsCount?: number;
    /** an integer with the number of impacted users. */
    impactedUsersCount?: number;
    /** this is the event or trigger type that is passed by the correlation engine. */
    eventCategory?: EVENT_CATEGORY;
    /** the runbooks that were part of this trigger. */
    runbooks?: Array<RunbookOutput>;
}

/** an enum which defines all the valid event categories */
export enum EVENT_CATEGORY {
    /** the enumerated value for the site outage event type. */
    SITE_OUTAGE_ISSUE                       = "SITE_OUTAGE_ISSUE",
    /** the enumerated value for the device down event type. */
    DEVICE_DOWN_ISSUE                       = "DEVICE_DOWN_ISSUE",
    /** the enumerated value for the interface down event type. */
    INTERFACE_DOWN_ISSUE                    = "INTERFACE_DOWN_ISSUE",
    /** the enumerated value for the interface performance event type. */
    INTERFACE_PERFORMANCE_ISSUE             = "INTERFACE_PERFORMANCE_ISSUE",
    /** the enumerated value for the application location performance event type. */
    APPLICATION_LOCATION_PERFORMANCE_ISSUE  = "APPLICATION_LOCATION_PERFORMANCE_ISSUE",
    /** the enumerated value for the site application event type. */
    SITE_APPLICATION_PERFORMANCE_ISSUE      = "SITE_APPLICATION_PERFORMANCE_ISSUE",
    /** the enumerated value for the single location multi app event type. */
    SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE  = "SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE",
    /** the enumerated value for the single app multi location event type. */
    SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE  = "SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE",
    /** the enumerated value for the webhook event type. */
    WEBHOOK                                 = "WEBHOOK",
    /** the enumerated value for the multi-device down event type. */
    MULTI_DEVICE_DOWN_ISSUE                 = "MULTI_DEVICE_DOWN_ISSUE",
    /** the enumerated value for the incident note added trigger. */
    INCIDENT_NOTE_ADDED                     = "INCIDENT_NOTE_ADDED",
    /** the enumerated value for the incident note updated trigger. */
    INCIDENT_NOTE_UPDATED                   = "INCIDENT_NOTE_UPDATED",
    /** the enumerated value for the incident note deleted trigger. */
    INCIDENT_NOTE_DELETED                   = "INCIDENT_NOTE_DELETED",
    /** the enumerated value for the incident indicators updated trigger. */
    INCIDENT_INDICATORS_UPDATED             = "INCIDENT_INDICATORS_UPDATED",
    /** the enumerated value for the incident ongoing changed trigger. */
    INCIDENT_ONGOING_CHANGED                = "INCIDENT_ONGOING_CHANGED",
    /** the enumerated value for the incident status changed trigger. */
    INCIDENT_STATUS_CHANGED                 = "INCIDENT_STATUS_CHANGED",
    /** the enumerated value for the impact analysis ready trigger. */
    IMPACT_ANALYSIS_READY                   = "IMPACT_ANALYSIS_READY",
}

/** this constant is used to sort the automation mappings cards.  Any index with a 100 is a 
 *  category that was not in use at the time this structure was created.  It will sort to the 
 *  end of the list.  Note each category repeats the indices because each category is sorted
 *  separately.  The current categories are incident runbook triggers, lifecycle runbook 
 *  triggers and webhook triggers. */
export const EVENT_CATEGORY_INDEX = {
    /** the sort index for the site outage event type. */
    [EVENT_CATEGORY.SITE_OUTAGE_ISSUE]:                         100,
    /** the sort index for the device down event type. */
    [EVENT_CATEGORY.DEVICE_DOWN_ISSUE]:                         3,
    /** the sort index for the interface down event type. */
    [EVENT_CATEGORY.INTERFACE_DOWN_ISSUE]:                      5,
    /** the sort index for the interface performance event type. */
    [EVENT_CATEGORY.INTERFACE_PERFORMANCE_ISSUE]:               6,
    /** the sort index for the application location performance event type. */
    [EVENT_CATEGORY.APPLICATION_LOCATION_PERFORMANCE_ISSUE]:    0,
    /** the sort index for the site application event type. */
    [EVENT_CATEGORY.SITE_APPLICATION_PERFORMANCE_ISSUE]:        100,
    /** the sort index for the single location multi app event type. */
    [EVENT_CATEGORY.SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE]:    1,
    /** the sort index for the single app multi location event type. */
    [EVENT_CATEGORY.SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE]:    2,
    /** the sort index for the webhook event type. */
    [EVENT_CATEGORY.WEBHOOK]:                                   0,
    /** the sort index for the multi-device down event type. */
    [EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE]:                   4,
    /** the sort index for the incident note added trigger. */
    [EVENT_CATEGORY.INCIDENT_NOTE_ADDED]:                       2,
    /** the sort index for the incident note updated trigger. */
    [EVENT_CATEGORY.INCIDENT_NOTE_UPDATED]:                     3,
    /** the sort index for the incident note deleted trigger. */
    [EVENT_CATEGORY.INCIDENT_NOTE_DELETED]:                     100,
    /** the sort index for the incident indicators updated trigger. */
    [EVENT_CATEGORY.INCIDENT_INDICATORS_UPDATED]:               1,
    /** the sort index for the incident ongoing changed trigger. */
    [EVENT_CATEGORY.INCIDENT_ONGOING_CHANGED]:                  4,
    /** the sort index for the incident status changed trigger. */
    [EVENT_CATEGORY.INCIDENT_STATUS_CHANGED]:                   5,
    /** the sort index for the impact analysis ready trigger. */
    [EVENT_CATEGORY.IMPACT_ANALYSIS_READY]:                     0,
};

/** defines the detection type.*/
export type Detection = DetectionAndTriggerBase & {}

/** defines the trigger type.*/
export type Trigger = DetectionAndTriggerBase & {}

/** defines the base for the detection and trigger types.*/
export type DetectionAndTriggerBase = {
    /** the id of the trigger.*/
    id: string;
    /** the id of the incident that the trigger was created in.*/
    incidentId?: string;
    /** a String with the description of the incident. */
    description?: string;
    /** the entity or subject of the trigger, for example the interface, device, or app that triggered the incident. */
    entity?: Entity;
    /** the time at which the incident was generated in seconds.nanoseconds. */
    generated?: string;
    /** the time at which the detection or trigger was last updated in seconds.nanoseconds. */
    lastUpdated?: string;
    /** ask Marcello and Alex what is this. */
    timestamp?: string;
    /** the time at which the incident had its earliest indicator in seconds.nanoseconds. */
    earliestIndicator?: string;
    /** the time at which the incident had its latest indicator in seconds.nanoseconds. */
    latestIndicator?: string;
    /** the count of the number of indicators in this trigger. */
    indicatorsCount?: number;
    /** the array of indicator mappings. */
    indicatorMappings?: Array<IndicatorMapping>;
    /** the runbooks that were part of this trigger. */
    runbooks?: Array<RunbookOutput>;
    /** the indicators associated with this detection or trigger. */
    indicators?: Array<Indicator>;
    /** the primary indicator for the detection or trigger. */
    primaryIndicator?: Indicator;
}

/** defines the indicator mapping type. */
export type IndicatorMapping = {
    /** the uuid of the entity, for example "4fbcac1c-29cc-11ed-afa9-0a53e864e1f3". */
    entityId: string;
    /** the id of the metric, for example out_utilization. */
    metric: string;
    /** a string with the anomaly kind. */
    kind: string;
    /** the id of the data source that the entity and/or its data came from. */
    sourceId?: string;
    /** the entity object */
    entity?: Entity;
}

/** defines the indicator mapping type. */
export type ComplexIndicatorMapping = {
    /** a boolean value which, if true, indicates that this indicator is the primary indicator. */
    isPrimary?: boolean;
    /** the actual entity object that corresponds to the entity id. */
    entity?: Entity;
} & IndicatorMapping;

/** defines the indicator type. */
export type Indicator = {
    /** a string with the indicator id. */
    id?: string;
    /** a boolean value which, if true, indicates that this indicator is the primary indicator. */
    isPrimary?: boolean;
    /** a boolean value which, if true, indicates that this indicator is the earliest indicator. */
    isEarliest?: boolean;
    /** the entity that the indicator occurred on. */
    entity?: Entity;
    /** the id of the metric, for example out_utilization. */
    metric: string;
    /** the indicator type or kind, for example "TOO_HIGH". */
    kind: INDICATOR_TYPE;
    /** the start time of the indicator in seconds.nanoseconds. */
    startTime?: string;
    /** the end time of the indicator in seconds.nanoseconds. */
    endTime?: string;
    /** the indicator details. */
    details?: IndicatorDetails;
    /** ask Marcello and Alex what is this. */
    timestamp?: string;
}

/** defines the indicator details type. */
export type IndicatorDetails = {
    /** the actual value of the metric. */
    actualValue?: number;
    /** the expected value of the metric. */
    expectedValue?: number;
    /** the upper threshold. */
    acceptableHighValue?: number;
    /** the lower threshold. */
    acceptableLowValue?: number;
    /** the granularity of the indicator data. */
    granularity?: number;
}

/** defines the indicator samples type. */
export type IndicatorSample = {
    /** the value of the sample. */
    details: IndicatorSampleDetails;
}

/** defines the indicator sample details type. */
export type IndicatorSampleDetails = {
    /** the array of indicator sample values. */
    samples: Array<IndicatorSampleValue>;
}

/** defines the indicator sample value type. */
export type IndicatorSampleValue = {
    /** the value of the sample. */
    value: number;
    /** the timestamp of the samples in seconds.nanoseconds. */
    timestamp: string;
}

/** defines the indicator timeseries type. */
export type IndicatorTimeseries = {
    /** the end time of the time series in seconds.nanoseconds. */
    endTime: string;
    /** the array of timeseries data values. */
    timeseries: Array<IndicatorTimeseriesValue>;
}

/** defines the indicator timeseries value type. */
export type IndicatorTimeseriesValue = {
    /** the actual value of the metric. */
    actualValue?: number;
    /** the expected value of the metric. */
    expectedValue?: number;
    /** the upper threshold. */
    acceptableHighValue?: number;
    /** the lower threshold. */
    acceptableLowValue?: number;
    /** the timestamp of the samples in seconds.nanoseconds. */
    timestamp: string;
}

/** an enum which defines all the valid automation/trigger categories/types. */
export enum CATEGORY_TYPES {
    /** the enumerated value for the analytics triggers type. */
    "INCIDENT" = "INCIDENT",
    /** the enumerated value for the incident lifecycle triggers type. */
    "LIFECYCLE"  = "LIFECYCLE",
    /** the enumerated value for the site webhook trigger type. */
    "EXTERNAL"   = "EXTERNAL"
}

/** a list with all the categories. */
export const CATEGORIES = [CATEGORY_TYPES.INCIDENT, CATEGORY_TYPES.LIFECYCLE, CATEGORY_TYPES.EXTERNAL];

/** defines the triggers categories. */
export const CATEGORY_TO_TRIGGER_MAP = {
    [CATEGORY_TYPES.INCIDENT]: [
        EVENT_CATEGORY.SITE_OUTAGE_ISSUE, EVENT_CATEGORY.DEVICE_DOWN_ISSUE, EVENT_CATEGORY.INTERFACE_DOWN_ISSUE, 
        EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE, EVENT_CATEGORY.INTERFACE_PERFORMANCE_ISSUE, 
        EVENT_CATEGORY.APPLICATION_LOCATION_PERFORMANCE_ISSUE, EVENT_CATEGORY.SITE_APPLICATION_PERFORMANCE_ISSUE,
        EVENT_CATEGORY.SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE, EVENT_CATEGORY.SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE
    ],
    [CATEGORY_TYPES.LIFECYCLE]: [
        EVENT_CATEGORY.INCIDENT_NOTE_ADDED, EVENT_CATEGORY.INCIDENT_NOTE_UPDATED, EVENT_CATEGORY.INCIDENT_NOTE_DELETED, 
        EVENT_CATEGORY.INCIDENT_INDICATORS_UPDATED, EVENT_CATEGORY.INCIDENT_ONGOING_CHANGED,  
        EVENT_CATEGORY.INCIDENT_STATUS_CHANGED, EVENT_CATEGORY.IMPACT_ANALYSIS_READY
    ],
    [CATEGORY_TYPES.EXTERNAL]: [EVENT_CATEGORY.WEBHOOK],
}

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { TIR_CHART_REFLOW_EVENT, Icon } from "@tir-ui/react-components";
import { IconNames } from '@blueprintjs/icons';

import { routeType } from "config/routes";

import { TopNavigation } from "../top-navigation/TopNavigation";
import { ConfirmModal, ModalRenderer } from "components/common/modal";
import { MODALS } from "config";
import { Button, Classes } from "@blueprintjs/core";

import { Container, Row, Col } from 'react-bootstrap';
import { Resizable, HandleComponent } from 're-resizable';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider';
import { IS_EMBEDDED } from "components/enums/QueryParams";

const AuthService  = AuthServiceProvider.getService();
const animateSideBar = false;

const sidebarToggledEvent = () => {
    setTimeout(() => {
        //Dispatch toggle sidebar event. Can be used within the app to handle any functions to be called after toggling
        // of sidebar
        window.dispatchEvent(new Event("toggleSidebar"));
        //Call the reflow event of charts to adjust the charts when sidebar is toggled
        window.dispatchEvent(new Event(TIR_CHART_REFLOW_EVENT));
        // Dispatch the actual resize event on window so that highcharts will know to resize
        window.dispatchEvent(new Event("resize"));
    }, animateSideBar ? 500 : 5)
};

const SIDEBAR_DOCKABLE = true;

/** Renders the main application layout.
 *  @param props the properties passed in.
 *  @returns JSX with the main application layout component.*/
function MainLayout(props: {
    routes: { [index: string]: routeType };
    sideMenu: React.ReactNode;
    defaultRoute: routeType;
    basename?: string;
}): JSX.Element {
    // sidebarDocked = true means sidebar will open in a floating format. When it's false,
    // sidebar will take up horizontal space and push the rest of the page's content to the right
    const [sidebarDocked, setSidebarDocked] = useState(
        SIDEBAR_DOCKABLE ? (localStorage.getItem("sidebarDocked") ? localStorage.getItem("sidebarDocked") === "true" : false) : false);
    const [sidebarOpen, setSidebarOpen] = useState(
        // If side bar open state was "false" in local storage, use it as it is
        localStorage.getItem("sidebarOpen") === "false" ? false : (
            // If side bar was docked (i.e. floating), then always show it as closed when app loads.
            // If sidebar was not floating, then show it as open or closed based on state stored in localstorage
            
            (sidebarDocked === true ? false : (localStorage.getItem("sidebarOpen") ? localStorage.getItem("sidebarOpen") === "true" : false))
        )
    );

    const toggleSidebarOpen = useCallback(
        (newState = null) => {
            const newSidebarOpenState =
                newState === null ? !sidebarOpen : newState;
            setSidebarOpen(newSidebarOpenState);
            localStorage.setItem(
                "sidebarOpen",
                newSidebarOpenState ? "true" : "false"
            );
            if (!sidebarDocked) {
                sidebarToggledEvent();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sidebarOpen, setSidebarOpen]
    );

    const toggleSidebarDocked = useCallback(
        (newState = null) => {
            const newSidebarDockedState =
                newState === null ? !sidebarDocked : newState;
            setSidebarDocked(newSidebarDockedState);
            localStorage.setItem(
                "sidebarDocked",
                newSidebarDockedState ? "true" : "false"
            );
            toggleSidebarOpen(newSidebarDockedState ? false : true);
        },
        [sidebarDocked, setSidebarDocked, toggleSidebarOpen]
    );

    useEffect(() => {
        //bit of a hack to avoid the issue of the charts being overdrawn because they rendered before the container decided their width
        //fixable only by having our own sidebar component that behaves how we want
        setTimeout(sidebarToggledEvent, animateSideBar ? 100 : 5);
    }, []);

    //this is basic, we might need something more complex for nested routes
    const routes = !AuthService.getSubscription() ?
        Object.entries(props.routes).map(([k, r]) => (
            <Route key={k} path={r.path} component={r.component} />
        )) :
        // Only allow impact-dashboard for no active subscription
        <Route key={'impact-dashboard'} path={props.routes['impact-dashboard'].path} component={props.routes['impact-dashboard'].component} />

    const getWindowWidth = () => {
        return Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
    };
    function hideSidebarOnClick () :void {
        if (sidebarOpen && (getWindowWidth() < 768 || sidebarDocked)) {
            setSidebarOpen(false);
        }
    }

    const handler: HandleComponent = {
        left: <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10
        }}><Icon className="drag-handle" icon={IconNames.DRAG_HANDLE_VERTICAL}/></div>
    };

    return (
        <Container fluid className="main-layout d-flex flex-column h-100" onClick={hideSidebarOnClick}>
            <Router basename={props.basename}>
	    	    {!IS_EMBEDDED ? (
                <Row className="border-bottom-dark">
                    <TopNavigation
                        sidebarDocked={sidebarDocked}
                        sidebarOpen={sidebarOpen}
                        toggleSidebarOpen={toggleSidebarOpen}
                    />
                </Row> 
                ) : (
                    <></>
                )}
                <Row className="flex-grow-1 overflow-auto position-relative">
                    {!IS_EMBEDDED ? ( 
                        <Col sm="auto"
                        className={classNames({
                            "sidebar": true,
                            "sidebarShowOver": sidebarOpen && sidebarDocked,
                            "noanim": !animateSideBar,
                            "p-0": true,
                            "border-0": !sidebarOpen,
                            "d-flex": sidebarOpen,
                            "flex-column": true,
                            //"overflow-auto": true,
                            "mh-100": true,
                            "position-absolute": sidebarDocked,
                            "collapsed": !sidebarOpen,
                            "docked": sidebarDocked
                        })}
                    >
                        {
                            SIDEBAR_DOCKABLE && 
                                <Button
                                    className={classNames({
                                        [Classes.MINIMAL]: true,
                                        "toggle-docking": true,
                                        "d-none": true, 
                                        "d-sm-flex": true
                                    })}
                                    icon={sidebarDocked ? "unpin" : "pin"}
                                    onClick={(e) => {
                                        toggleSidebarDocked();
                                        e.stopPropagation();
                                    }}
                                />
                        }
                        {props.sideMenu}
                    </Col>
                    ) : (
                        <></>
                    )}
                    <Col className="d-flex p-0 h-100 flex-column container overflow-auto">
                        <div className="routed-content flex-grow-1 overflow-auto h-100">
                            <Switch>
                                <Redirect
                                    exact
                                    from="/"
                                    to={props.defaultRoute.path}
                                />
                                {routes}
                                <Route path="*">
                                    <Redirect to={props.defaultRoute.path} />
                                </Route>
                            </Switch>
                        </div>
                        <div id="footer-pane" className="page-footer animated bg-white shadow border-top flex-grow-0 d-flex flex-column justify-content-center
                        "></div>
                    </Col>
                    <Resizable
                        handleComponent={handler}
                        className="resizable-details-panel d-none overflow-y-hidden"
                        minWidth="400px"
                        maxWidth="80%"
                        maxHeight="100%"
                        style={{position: "absolute", top: 0, bottom: 0, right: 0}}
                        enable={{top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:true, topLeft:true}}
                    >
                        <Col id="right-details-pane" className="d-none flex-grow-0 p-0 bg-light shadow h-100 col-12 col-md-auto border-left resizable">
                        </Col>
                    </Resizable>
                </Row>
            </Router>
            <ModalRenderer modals={MODALS}/>
            <ConfirmModal/>
        </Container>
    );
}

export { MainLayout };

/** This module contains the DataOceanService that can be used to query the data ocean API
 *  @module
 */
import { FILTER_TYPE, FilterEntry } from 'pages/navigator/Navigator.type';
import { ApiService } from 'utils/services/ApiService';

// The URL for the API server.
export const DATA_OCEAN_BASE_URL = '/api/affogato/';

/** this class defines the DataSourceApiService. */
class DataOceanApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(DATA_OCEAN_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
     protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs the specified data ocean query and returns the DO data.
     *  @param query the DataOcean query with the data.
     *  @returns a Promise which resolves to the returned data. */
    runDataOceanQuery(query: any): Promise<any> {
/*        
        return new Promise((resolve, reject) => {
            setTimeout(
                () => {
                    let data: any = {searchItems: {page: []}};
                    if (query.groupBy.includes("user_name")) {
                        data = {searchItems: {page: [
                            {
                                keys: [{id: "user_name", value: "JSMITH"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "ASTONE"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "TEST"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            }
                        ]}};    
                    } else if (query.groupBy.includes("app_name")) {
                        data = {searchItems: {page: [
                            {
                                keys: [{id: "user_name", value: "Excel"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "Exchange"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "Outlook"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            }
                        ]}};    
                    }                    
                    resolve(data);
                },
                1 * 1000
            );
        });
*/
        /*
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super
                .get<string>(`dataocean/1.0/tenants/${utid}/product_info/${id}`)
                .then(
                    (results) => {
                        resolve(results);
                    },
                    (err) => {
                        reject(err);
                        console.error(err);
                    }
                );
        });
        */
       /*
       const translatedQuery = {
        "obj_type": "network_host.traffic",
        "time_series": false,
        "limit": 10,
        "metrics": [
                  "in_throughput",
                  "out_throughput",
                  "sum_bytes",
                  "sum_in_bytes",
                  "sum_out_bytes",
                  "sum_packets"
        ],
        "filters": {"location": [{"name": "Default-Internet"}]},
        "time": {"start":"1709394951","end":"1709398551"}
      };
      */

        const filters: any = {};
        if (query.filter.filters) {
            for (const filter of query.filter.filters) {
                if ([FILTER_TYPE.network_host, FILTER_TYPE.network_client, FILTER_TYPE.network_server].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {ipaddr: value};});
                } else if ([FILTER_TYPE.network_interface].includes((filter as FilterEntry).type)) {
                    // Value is ipaddr:ifindex
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {
                        const ipaddr = value.substring(0, value.lastIndexOf(":"));
                        const ifindex = value.substring(value.lastIndexOf(":") + 1, value.length);
                        return {ipaddr, ifindex};
                    });
                } else if ([FILTER_TYPE.protocol, FILTER_TYPE.dscp].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {number: value};});
                    //filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {name: value};});
                } else if ([FILTER_TYPE.data_source].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {id: value};});
                } else {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {name: value};});
                }
            }
        }

        let objType = "network_host.traffic";
        let groupBy: string[] | undefined = undefined;
        if (query.dataset === "npm_plus.traffic") {
            objType = "npm_plus.traffic";
            // Need to remove this
            groupBy = query.groupBy;
        } else if (query.dataset === "app_response_and_profiler") {
            if (query.groupBy.includes("network_host")) {
                objType = "network_host.traffic";
            } else if (query.groupBy.includes("network_client")) {
                objType = "network_client.traffic";
            } else if (query.groupBy.includes("network_server")) {
                objType = "network_server.traffic";
            } else if (query.groupBy.includes("application")) {
                objType = "application.traffic";
            } else if (query.groupBy.includes("location")) {
                objType = "location.traffic";
            } else if (query.groupBy.includes("client_location")) {
                objType = "client_location.traffic";
            } else if (query.groupBy.includes("server_location")) {
                objType = "server_location.traffic";
            }    
            // Get data from AR11 by setting the location filter
            if (!filters.location) {
                filters.location = [];
            }
            filters.location.push({name: "Default-Internet"});
        } else if (query.dataset === "profiler_traffic") {
            if (query.groupBy.includes("network_interface")) {
                objType = "network_interface.traffic";
            } else if (query.groupBy.includes("network_device")) {
                objType = "network_device.traffic";
            } else if (query.groupBy.includes("network_host")) {
                objType = "network_host.traffic";
            } else if (query.groupBy.includes("network_client")) {
                objType = "network_client.traffic";
            } else if (query.groupBy.includes("network_server")) {
                objType = "network_server.traffic";
            } else if (query.groupBy.includes("application")) {
                objType = "application.traffic";
            } else if (query.groupBy.includes("location")) {
                objType = "location.traffic";
            } else if (query.groupBy.includes("client_location")) {
                objType = "client_location.traffic";
            } else if (query.groupBy.includes("server_location")) {
                objType = "server_location.traffic";
            } else if (query.groupBy.includes("dscp")) {
                objType = "dscp.traffic";
            } else if (query.groupBy.includes("protocol")) {
                objType = "protocol.traffic";
            }   
        } else {
            // This object type has no group by.  The group by included was fake.
            objType = query.dataset;
        }

        // Translate the DAL query into a DO query
        const translatedQuery = JSON.parse(JSON.stringify(query));
        translatedQuery.obj_type = objType; //translatedQuery.dataset;
        translatedQuery.time = {start: translatedQuery.filter.time.startTime, end: translatedQuery.filter.time.endTime};
        //if (query.dataset === "profiler_traffic") {
            // Profiler is not accepting the start and end format
            translatedQuery.time = {start: translatedQuery.time.start.split(".")[0], end: translatedQuery.time.end.split(".")[0]};
        //}
        translatedQuery.limit = translatedQuery.top;
        translatedQuery.filters = filters;
        if (query.dataset !== "npm_plus.traffic") {
            if (query.dataset === "app_response_and_profiler") {
                translatedQuery.filters = {...translatedQuery.filters, location: [{name: "Default-Internet"}]};
                translatedQuery.filters = {...translatedQuery.filters, data_source: [{name: "oak-valloy40", id: "28133108-379d-4c32-b829-db6060d5170d"}]};    
            }
        } else {
            translatedQuery.group_by = groupBy;
        }
        delete translatedQuery.dataset;
        delete translatedQuery.filter;
        delete translatedQuery.order;
        delete translatedQuery.groupBy;
        delete translatedQuery.keys;
        delete translatedQuery.top;
        delete translatedQuery.skip;

        return new Promise((resolve, reject) => {
            const utid = ApiService.AUTH_SERVICE.getTenantId();
            super
                .post<any>(`dataocean/3.0/tenants/${utid}/query`, translatedQuery)
                .then(
                    (response: any) => {
                        resolve({searchItems: {page: response.data}});
                    },
                    (err) => {
                        reject(err.response);
                    }
                );
        });
    }
}

/** a constant with an instance of the DataOceanApiService. */
const DataOceanService = new DataOceanApiService();
export { DataOceanService };
